<template>
  <div v-show="show">
    <vs-alert
      :active.sync="exportingLocal"
      closable
      color="primary"
      icon-pack="feather"
      icon="icon-clock"
      close-icon="icon-x">
      {{ $t(`${messagePrefix}.ExportingMsg`, { entity: this.entity }) }}
    </vs-alert>

    <vs-alert
      :active.sync="exportedLocal"
      closable
      color="success"
      icon-pack="feather"
      icon="icon-check"
      close-icon="icon-x">
      <span v-html="$t(`${messagePrefix}.ExportedMsg`, {
        entity: this.entity, url: this.fileUrl
      })">
      </span>
    </vs-alert>
  </div>
</template>

<script>

/**
 * Component to show exporting alert messages
 *
 * @module views/modules/components/ExportAlertInfo
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} [exporting=false] - indicates if an export operation is being carried out
 * @vue-prop {boolean} [exported=false] - indicates if an export operation was carried out
 * @vue-prop {string} entity - entity to export
 * @vue-prop {string} fileUrl - url to download the file exported
 * @vue-data {boolean} exportingLocal- indicates if an local export operation is being carried out
 * @vue-data {boolean} exportedLocal- indicates if an local export operation was carried out
 * @vue-computed {boolean} show - indicate if show or no the alerts
 * @vue-event {void} exporting - watch to set exportingLocal
 * @vue-event {void} exported - watch to set exportedLocal
 * @vue-event {void} exportingLocal - watch to emit update of exportingLocal
 * @vue-event {void} exportedLocal - watch to emit update of exportedLocal
 */
export default {
  name: 'ExportAlertInfo',
  i18n: {
    messages: {
      en: {
        WithEmail: {
          ExportingMsg: 'Your {entity} are being exported. Once they are ready, you will receive a file by email. You can also download your file directly.',
          ExportedMsg: 'Your {entity} have been exported successfully, and you will receive a file by email. You may also download your file directly from <a class="ml-0 link-contextual" href="{url}">here</a>.',
        },
        WithoutEmail: {
          ExportingMsg: 'Your {entity} are being exported. Once they are ready, you will download your file directly.',
          ExportedMsg: 'Your {entity} have been exported successfully, and you may download your file directly from <a class="ml-0 link-contextual" href="{url}">here</a>.',
        },

      },
    },
  },
  props: {
    exporting: {
      type: Boolean,
      required: false,
      default: false,
    },
    exported: {
      type: Boolean,
      required: false,
      default: false,
    },
    sendEmail: {
      type: Boolean,
      required: false,
      default: false,
    },
    entity: {
      type: String,
      required: true,
    },
    fileUrl: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      exportingLocal: this.exporting,
      exportedLocal: this.exported,
    };
  },
  computed: {
    show() {
      return this.exporting || this.exported;
    },
    messagePrefix() {
      return this.sendEmail ? 'WithEmail' : 'WithoutEmail';
    },
  },
  watch: {
    exporting(val) {
      if (val) {
        this.exportedLocal = false;
      }

      this.exportingLocal = val;
    },
    exported(val) {
      if (val) {
        this.exportingLocal = false;
      }

      this.exportedLocal = val;
    },
    exportingLocal(val) {
      if (!val) {
        this.$emit('update:exporting', false);
      }
    },
    exportedLocal(val) {
      if (!val) {
        this.$emit('update:exported', false);
      }
    },
  },
};
</script>
