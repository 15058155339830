import Vue from 'vue';
import { mapActions } from 'vuex';
import NameConstructor from '@/views/modules/_common/name.constructor';
import OptionsDropDownFilter from '@/views/modules/contacts/filters/OptionsDropDownFilter';

/**
 * Categories drop down filter
 *
 * @module views/modules/contacts/contact/filters/CategoriesDropDownFilter
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default Vue.extend({
  name: 'CategoriesDropDownFilter',
  template: `
    <options-drop-down-filter
      v-if="params"
      ref="OptionsDropDownFilter"
      id="categories-drop-down-filter-wrapper"
      :params="params"
      :selected-items="selectedCategories"
      :fetch-function="fetchCategoryOptions"
      :filter-condition-options="categoriesConditionOptions"
      :placeholder="$t('$ContactModule.SelectCategoriesOptions')"
      :manage-text="$t('$ContactModule.ManageAudienceAttributes')"
      manage-route-name="custom-fields"
      :manage-redirect-only-event="false"
      :disabled="false"
      :filter-type="this.$enums.AppFilterType.CATEGORIES"
      :ag-grid-floating-filter="agGridFloatingFilter"
      @create="addItemToCategory"
      @input="selectedCategoryOptionsChanged">
    </options-drop-down-filter>
  `,
  components: {
    OptionsDropDownFilter,
  },
  props: {
    selectedCategories: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    agGridFloatingFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      modelCategory: null,
      condition: this.$enums.AppFilterOperation.ONE,
      categoriesConditionOptions: Object.values(
        this.$enums.AppFilter.FilterType.Categories.Type,
      ).map((condition) => ({
        label: this.$t(`$AppFilters.$Operation.${condition}`),
        value: condition,
      })),
    };
  },
  methods: {
    ...mapActions({
      fetchAttribute: 'attribute/fetchAttribute',
      addItemToAttribute: 'attribute/addItemToAttribute',
    }),
    async fetchCategoryOptions(params) {
      this.modelCategory = await this.fetchAttribute(this.params.colDef.field);

      const items = params.filters && params.filters.name && params.filters.name.filter
        ? this.modelCategory.items.reverse().filter((item) => item.name.toLocaleLowerCase().indexOf(
          params.filters.name.filter.toLocaleLowerCase(),
        ) > -1)
        : this.modelCategory.items.reverse();

      return {
        data: items.slice(params.skip, params.skip + params.limit),
        count: items.length,
      };
    },
    async addItemToCategory(name) {
      const newItem = new NameConstructor(name);

      if (this.modelCategory) {
        await this.addItemToAttribute({
          attributeId: this.modelCategory.id,
          payload: newItem,
        });

        await this.$refs.OptionsDropDownFilter.resetSearch();
      }
    },
    selectedCategoryOptionsChanged(tags) {
      this.$emit('update:selected-categories', tags);
    },
    getModel() {
      return this.$refs.OptionsDropDownFilter.getModel();
    },
    setModel(model) {
      this.$refs.OptionsDropDownFilter.setModel(model);
    },
  },
});
