/**
 * Common props, data & methods for filters components on segment create or edit
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditBooleanFilter
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {any} value - value
 * @vue-prop {string} [fieldName] - field name to use in html component
 * @vue-prop {boolean} [disabled = false] - indicate if html component must be disabled
 * @vue-data {any} valueLocal - value local
 */
export default {
  props: {
    value: {
      required: true,
    },
    fieldName: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valueLocal: this.value,
    };
  },
  watch: {
    value(val) {
      this.valueLocal = val;
    },
    valueLocal(val) {
      this.$emit('input', val);
    },
  },
};
