import { render, staticRenderFns } from "./ImportExcelToJsonStep1UploadFile.vue?vue&type=template&id=f896225e&scoped=true&"
import script from "./ImportExcelToJsonStep1UploadFile.vue?vue&type=script&lang=js&"
export * from "./ImportExcelToJsonStep1UploadFile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f896225e",
  null
  
)

export default component.exports