<template>
  <common-cell-renderer-actions
    :actions="filteredActionsFromParent"
    @restore="restore()"
    @view="viewRecord()"
    @clone="cloneRecord()"
    @edit="editRecord()"
    @delete="confirmDeleteRecord()">
  </common-cell-renderer-actions>
</template>

<script>
import CommonCellRendererActions from '@/views/modules/_components/cell-renderer/CommonCellRendererActions.vue';
import commonSingleCellRendererActions from '@/views/modules/_mixins/commonSingleCellRendererActions';

/**
 * Cell renderer to trash list
 *
 * @module views/modules/contacts/contact/ContactListCellRendererActions
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-event {void} restore - restore one contact
 */
export default {
  name: 'ContactListCellRendererActions',
  components: {
    CommonCellRendererActions,
  },
  mixins: [commonSingleCellRendererActions],
  computed: {
    isOptOut() {
      return this.params && this.params.data
        ? this.params.data.marketingStatus.value
        === this.$enums.Contact.MarketingStatus.UNSUBSCRIBED
        : false;
    },
  },
  methods: {
    restore() {
      this.params.context.componentParent.confirmRestoreRecord(this.params.node.data);
    },
  },
};
</script>
