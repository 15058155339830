<template>
  <import-excel-to-json-card
    ref="card"
    :collapse-action="collapseAction"
    :collapsed="true"
    :class="{'opacity-50': !confirmedPreviousStep}"
    card-title-id="import-excel-to-json-card-4-title"
    :icon="confirmed ? 'check_box' : 'looks_4'"
    :color="confirmed ? 'success' : ''"
    :title="$t('CardTitle')"
    :subtitle="$t('CardSubTitle')">

    <div
      v-if="importResult !== null"
      class="vx-row">
      <div :class="statisticsCardContainerClasses">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UploadIcon"
          icon-right
          :statistic="toImportCount"
          :statisticTitle="$t('ToImportCardText', {
              entity: $tc(`$Entities.${entity}`, toImportCount),
            })"/>
      </div>

      <div :class="statisticsCardContainerClasses">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="CheckCircleIcon"
          icon-right
          color="success"
          :statistic="importResult.numberSaved"
          :statisticTitle="$t('ImportedCardText', {
              entity: $tc(`$Entities.${entity}`, importResult.numberSaved),
            })" />
      </div>

      <div
        v-if="importResult.numberDuplicate"
        :class="statisticsCardContainerClasses">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="AlertTriangleIcon"
          icon-right
          color="warning"
          :statistic="importResult.numberDuplicate"
          :statisticTitle="$t('DuplicateCardText', {
              entity: $tc(`$Entities.${entity}`,
              importResult.numberDuplicate === 0
              ? 2
              : importResult.numberDuplicate
              ),
            }) | sentencecase"/>
      </div>

      <div :class="statisticsCardContainerClasses">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="XCircleIcon"
          icon-right
          color="danger"
          :statistic="importResult.numberFailed"
          :statisticTitle="$t('NotImportedCardText', {
              entity: $tc(`$Entities.${entity}`,
              importResult.numberFailed === 0
              ? 2
              : importResult.numberFailed),
            })" />
      </div>
    </div>

    <div>
      <vs-table
        v-if="duplicateItems.length > 0"
        stripe
        pagination
        :max-items="10"
        search
        :data="duplicateItems">

        <template slot="header">
          <h5>
            {{ $t('DuplicateDataTitle', {
            entity: $tc(`$Entities.${entity}`, 2),
          }) | sentencecase }}
          </h5>
        </template>

        <template slot="thead">
          <vs-th
            v-for="header in mappedImportHeaders"
            :key="header.field"
            :sort-key="header.field">
            {{ header.headerName }}
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr">
            <vs-td
              v-for="(col, indexcol) in data[indextr]"
              :key="indexcol + indextr"
              :data="col" >
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div>
      <vs-table
        v-if="notImportedItems.length > 0"
        stripe
        pagination
        :max-items="10"
        search
        :data="notImportedItems">

        <template slot="header">
          <h5>
            {{ $t('NotImportedDataTitle', {
            entity: $tc(`$Entities.${entity}`, 2),
          })  | sentencecase }}
          </h5>
        </template>

        <template slot="thead">
          <vs-th
            v-for="header in mappedImportHeaders"
            :key="header.field"
            :sort-key="header.field">
            {{ header.headerName }}
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr">
            <vs-td
              v-for="(col, indexcol) in data[indextr]"
              :key="indexcol + indextr"
              :data="col" >
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div>
      <div
        class="flex justify-center mt-2">
        <vs-button
          color="primary"
          @click="$emit('close')">
          {{ $t('BackButtonText') }}
        </vs-button>

        <vs-button
          class="ml-2"
          color="primary"
          @click="$emit('refresh')">
          {{ $t('NewImportButtonText') }}
        </vs-button>
      </div>
    </div>
  </import-excel-to-json-card>
</template>

<script>
import ImportExcelToJsonCard
from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonCard.vue';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';

import ImportExcelToJsonStepMixin from '@/views/modules/_components/import-exce-to-json/import-excel-to-json-step.mixin';

export default {
  name: 'ImportExcelToJsonStep4ImportReport',
  i18n: {
    messages: {
      en: {
        CardTitle: 'Import report',
        CardSubTitle: 'View your import report.',
        ToImportCardText: '{entity} to import',
        ImportedCardText: '{entity} imported',
        NotImportedCardText: '{entity} skipped',
        DuplicateCardText: '{entity} updated',
        NotImportedDataTitle: 'Skipped {entity}',
        DuplicateDataTitle: 'Updated {entity}',
        BackButtonText: 'Back to list',
        NewImportButtonText: 'New import',
      },
    },
  },
  components: {
    StatisticsCardLine,
    ImportExcelToJsonCard,
  },
  mixins: [ImportExcelToJsonStepMixin],
  props: {
    importResult: {
      type: [Object, null],
      required: false,
    },
    toImportCount: {
      type: Number,
      required: true,
    },
    mappedImportHeaders: {
      type: Array,
      required: true,
    },
    mappedImportData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    statisticsCardContainerClasses() {
      return this.importResult.numberDuplicate
        ? 'vx-col w-full md:w-1/4'
        : 'vx-col w-full md:w-1/3';
    },
    mappedImportDataWithImportIndexWithoutDefaultAttributes() {
      return this.mappedImportData.map((item, index) => ({
        ...item,
        importIndex: index,
      }));
    },
    duplicateItems() {
      if (this.importResult === null
        || this.importResult.numberSaved
        === this.mappedImportDataWithImportIndexWithoutDefaultAttributes.length) {
        return [];
      }

      return this.mappedImportDataWithImportIndexWithoutDefaultAttributes.filter(
        (source) => this.importResult.indicesDuplicated.includes(source.importIndex),
      ).map((item) => {
        const { importIndex, ...itemWithoutImportIndex } = item;
        return itemWithoutImportIndex;
      });
    },
    notImportedItems() {
      if (this.importResult === null
        || this.importResult.numberSaved
        === this.mappedImportDataWithImportIndexWithoutDefaultAttributes.length) {
        return [];
      }

      return this.mappedImportDataWithImportIndexWithoutDefaultAttributes.filter(
        (source) => this.importResult.indicesFailed.includes(source.importIndex),
      ).map((item) => {
        const { importIndex, ...itemWithoutImportIndex } = item;
        return itemWithoutImportIndex;
      });
    },
  },
  watch: {
    confirmedPreviousStep(val) {
      if (val) {
        if (this.$refs.card.isContentCollapsed()) {
          this.$refs.card.toggleContent();
        }
      }
    },
  },
};
</script>

<style lang="scss">

</style>
