<template>
  <div>
    <contact-list-importer
      v-if="showImportComponent"
      :column-defs-to-import="columnDefsToImport"
      :all-contact-attributes="allContactAttributes"
      @attribute-created="onAttributeSaved"
      @imported="resetDataSourceAndSelection()"
      @close="showImportComponent = false"
    />

    <div
      v-show="!showImportComponent"
      :class="[
        'vx-card',
        'py-6',
        !selectedFromCampaign ? 'px-6' : '',
        selectedFromCampaign ? 'no-shadow' : '',
        ]">
      <base-ag-grid-header
        v-if="!readonlyList"
        :rows-selected-count="rowsSelectedCountWithAllSelection"
        :filters-count="filtersAppliedCount"
        :filters-match.sync="filtersMatch"
        @reset-selection="setSelectedRowsNone()"
        @reset-filters="resetFiltersContacts()">
        <template v-slot:header-left>
          <items-per-page
            :count="count"
            :currentPage="currentPage"
            :paginationPageSize="paginationPageSize"
            @changed-page-size="paginationSetPageSize">
          </items-per-page>

          <list-action-drop-down
            v-if="isAnyRowsSelected"
            :options="allMultipleActionOptions"
            class="ml-3"
            @export="exportData()"
            @delete="confirmDeleteRecords()"
            @restore="confirmRestoreRecordsForTrashWithAgGrid()">
          </list-action-drop-down>
        </template>

        <template v-slot:header-right>
          <div class="flex flex-wrap-reverse gap-3 md:gap-0 justify-between">
            <vs-button
              v-if="!trash"
              color="primary"
              type="border"
              :size="headerButtonsSizeByResolution"
              icon-pack="feather"
              icon="icon-plus"
              class="mr-0 md:mr-3"
              @click="newItem">
              {{ $t("$General.AddNew") }}
            </vs-button>

            <vs-button
              v-if="!trash"
              color="primary"
              :size="headerButtonsSizeByResolution"
              icon-pack="feather"
              icon="icon-upload"
              class="mr-0 md:mr-3"
              @click="showImportComponent=true">
              {{ $t("$General.Import") }}
            </vs-button>

            <vs-button
              radius
              class="ml-auto md:ml-0"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-refresh-cw"
              @click="resetDataSourceAndSelection()">
            </vs-button>
          </div>
        </template>
      </base-ag-grid-header>

      <selection-alert-info
        v-model="selectionPageAlert"
        class="mb-5"
        :entity="entity"
        :count="count"
        :rows-selected-count="rowsSelectedCount"
        @select-all="setSelectedRowsAll()"></selection-alert-info>

      <export-alert-info
        class="mb-5"
        :entity="$tc(`$Entities.${entity}`, 2)"
        :exporting.sync="exportingList"
        :exported.sync="exportedList"
        :fileUrl="urlFileExported"
        :send-email="exportSendEmail"/>

      <contact-list-toolbar
        ref="contactListToolbar"
        v-if="!trash && !readonlyList"
        class="hidden md:block"
        :selected-segment.sync="selectedSegment"
        :filters="filtersModel"
        :filters-match="filtersMatch"
        :contacts-match-count="count"
        :selected-from-campaign="selectedFromCampaign"
        @new-attribute="activeModalAttributeCreateOrEdit=true"
        @new-segment="(name) => showSegmentModalCreateOrEdit($enums.Operation.CREATE, name)"
        @edit-segment="showSegmentModalCreateOrEdit($enums.Operation.EDIT)"/>

      <ag-grid-vue
        :key="agGridKey"
        ref="agGridTable"
        :domLayout="domLayout"
        :style="gridStyle"
        :components="components"
        :frameworkComponents="frameworkComponents"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :column-types="columnTypes"
        :getRowNodeId="getRowNodeId"
        :autoParamsRefresh="true"
        rowSelection="multiple"
        :animateRows="true"
        :suppressRowClickSelection="true"
        rowModelType="infinite"
        :pagination="hasPagination"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="suppressPaginationPanel"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
        :cacheBlockSize="cacheBlockSize"
        :maxBlocksInCache="maxBlocksInCache"
        :applyColumnDefOrder="true"
        @selection-changed="onSelectionChanged"
        @grid-ready="onGridReady"
        @model-updated="onModelUpdate"
        @grid-size-changed="onGridSizeChanged"
        @first-data-rendered="onFirstDataRendered"
        @row-clicked="onRowClicked"
        @row-double-clicked="onRowDoubleClicked"
        @filter-changed="onFilterChanged"
        @pagination-changed="onPaginationChanged">
      </ag-grid-vue>

      <vs-popup
        :title="titleModal"
        :active.sync="activeModalCreateOrEdit">
        <transition name="zoom-fade">
          <contact-list-create-or-edit
            v-if="showCreateOrEditComponent"
            :entity="entity"
            :operation="operation"
            :model-payload="recordSelected"
            :actions="actionsOnCreateOrEdit"
            :all-contact-attributes="allContactAttributes"
            @action="onActionFromCreateOrEditForTrash"
            @model-was-changed="(val) => this.createOrEditModelWasChanged=val"
            @saved="savedItemOnAgGridTable"
            @close="activeModalCreateOrEdit=false"
            @redirect="redirectFromCreateOrEdit">
          </contact-list-create-or-edit>
        </transition>
      </vs-popup>

      <vs-popup
        :title="titleModalAttribute"
        :active.sync="activeModalAttributeCreateOrEdit">
        <transition name="zoom-fade">
          <attribute-create-or-edit
            v-if="showCreateOrEditAttributeComponent"
            :entity="$enums.Entity.CONTACT_ATTRIBUTE"
            :operation="$enums.Operation.CREATE"
            @saved="onAttributeSaved"
            @close="activeModalAttributeCreateOrEdit=false">
          </attribute-create-or-edit>
        </transition>
      </vs-popup>

      <vs-popup
        :title="titleModalSegment"
        :active.sync="activeModalSegmentCreateOrEdit">
        <transition name="zoom-fade">
          <segment-create-or-edit
            v-if="showCreateOrEditSegmentComponent"
            :entity="$enums.Entity.SEGMENT"
            :operation="segmentModalOperation"
            :name="newSegmentName"
            :model-payload="selectedSegment"
            :filters="filtersModel"
            :filters-match="filtersMatch"
            replace-filters-on-edit
            @saved="onSegmentSaved"
            @close="activeModalSegmentCreateOrEdit=false">
          </segment-create-or-edit>
        </transition>
      </vs-popup>

      <vs-popup
        :title="exportModalTitle"
        :active.sync="activeModalToExport">
        <transition name="zoom-fade">
          <export-json-to-excel
            v-if="showExportComponent"
            :columns="getColumnsToExport"
            :data="rowsSelected"
            @close="activeModalToExport=false"
            @export="onExport">
          </export-json-to-excel>
        </transition>
      </vs-popup>
    </div>
  </div>

</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { mapActions, mapGetters } from 'vuex';
import { parsePhoneNumber } from 'libphonenumber-js';

import enums from '@/enums';
import { emailRegex, firstOrLastNameRegex } from '@/util/util.regex';

// Mixins
import commonTrashListCreateOrEditWithAgGrid from '@/views/modules/_mixins/commonTrashListCreateOrEditWithAgGrid';

// Custom Components
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import ExportJsonToExcel from '@/views/modules/_components/ExportJsonToExcel.vue';
import ListActionDropDown from '@/views/modules/_components/ListActionDropDown.vue';
import ContactListCreateOrEdit from '@/views/modules/contacts/ContactListCreateOrEdit.vue';
import AttributeCreateOrEdit from '@/views/modules/attribute/AttributeListCreateOrEdit.vue';
import SegmentCreateOrEdit from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEdit.vue';
import ExportAlertInfo from '@/views/modules/_components/ExportAlertInfo.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import ContactListToolbar from '@/views/modules/contacts/ContactListToolbar.vue';
import SelectionAlertInfo from '@/views/modules/SelectionAlertInfo.vue';
import ContactListImporter from '@/views/modules/contacts/ContactListImporter.vue';

// Cell Renderer
import CellRendererTags from '@/views/modules/contacts/cell-renderers/ContactListCellRendererTags.vue';
import CellRendererActions from '@/views/modules/contacts/cell-renderers/ContactListCellRendererActions.vue';
import CellRendererDate from '@/views/modules/_components/cell-renderer/CellRendererDate.vue';
import CellRendererCategories from '@/views/modules/contacts/cell-renderers/ContactListCellRendererCategories.vue';
import CellRendererBooleans from '@/views/modules/contacts/cell-renderers/ContactListCellRendererBooleans.vue';
import CellRendererMarketingStatus from '@/views/modules/contacts/cell-renderers/ContactListCellRendererMarketingStatus.vue';

// Filters
import TagsDropDownFilter from '@/views/modules/contacts/filters/TagsDropDownFilter';
import TagsFloatingFilter from '@/views/modules/contacts/filters/TagsFloatingFilter';
import CategoriesDropDownFilter from '@/views/modules/contacts/filters/CategoriesDropDownFilter';
import CategoriesFloatingFilter from '@/views/modules/contacts/filters/CategoriesFloatingFilter';
import agGridBooleanFilter from '@/views/modules/_components/filters/agGridBooleanFilter';
import agGridBooleanFloatingFilter from '@/views/modules/_components/filters/agGridBooleanFloatingFilter';
import agGridStatusFilter from '@/views/modules/_components/filters/agGridStatusFilter';
import agGridStatusFloatingFilter from '@/views/modules/_components/filters/agGridStatusFloatingFilter';

/**
 * Component to list and manage contacts
 *
 * @module views/modules/contacts/contact/ContactList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} [selectedFromCampaign=false] - indicate if the list if for select in campaign
 * @vue-data {string} [entity='Contact'] - Name of the entity associated to list
 * @vue-data {boolean} [cellRenderer=false] - indicate that component isn't a cell renderer
 * @vue-data {boolean} [dataSourceIsSet=false] - indicate if data source is already set
 * @vue-data {boolean} [insertedSelectedAudienceAttributes=false] -
 * indicate if attributes of selected audience was inserted in contacts to list
 * @vue-data {Object} [selectedSegment=null] - selected segment to filter contacts
 * @vue-data {Object} [filtersModel=null] - current filter model
 * @vue-data {string} [newSegmentName=''] - name for creation of new segment
 * @vue-data {Array.<Object>} [allContactAttributes=[]] - all contact attributes
 * @vue-data {boolean} [activeModalAttributeCreateOrEdit=false] -
 * to active or inactive the create or edit attribute modal
 * @vue-data {boolean} [showCreateOrEditAttributeComponent=false] -
 * to show or hide the create or edit attribute component
 * @vue-data {boolean} [activeModalSegmentCreateOrEdit=false] -
 * to active or inactive the create or edit segment modal
 * @vue-data {boolean} [showCreateOrEditSegmentComponent=false] -
 * to show or hide the create or edit segment component
 * @vue-data {string} [titleModalAttribute='$t'] -
 * title of modal to create or edit a contact attribute
 * @vue-data {string} [titleModalSegment=''] - title of modal to create or edit a segment
 * @vue-data {string} [segmentModalOperation='create'] - operation in segment modal
 * @vue-data {number} baseColumnDefsCount - Number of base columns defs
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - framework components to ag grid component
 * @vue-event {void} activeModalAttributeCreateOrEdit - watch to show
 * create or edit attribute component
 * @vue-event {void} activeModalSegmentCreateOrEdit - watch to show
 * create or edit segment component
 * @vue-event {void} selectedSegment - watch to set filters on segment changed
 * @vue-computed {Object[]} allMultipleActionOptions -
 * all multiple action options with trash options
 * @vue-event {void} created - hook to fetch data
 * @vue-event {void} exportContacts - export contacts
 * @vue-event {void} removeContact - remove an contact
 * @vue-event {void} removeContacts - remove many contacts
 * @vue-event {void} removeContact - restore an contact
 * @vue-event {void} removeContacts - restore many contacts
 * @vue-event {void} updatePageTitle - update the title of the page
 * @vue-event {void} setAgGridDataSource - set the ag grid datasource
 * @vue-event {void} fetchAgGridData - fetch data to ag grid table
 * @vue-event {void} fetchAudienceAttributes - fetch the audience attributes
 * @vue-event {void} onModelUpdate - called on model update
 * @vue-event {void} insertAttributesColumns -
 * insert contact attributes of selected audience as columns to ag grid table
 * @vue-event {void} deleteAttributesColumns - delete column attributes to ag grid table
 * @vue-event {void} getAgGridFilterFromAttributeType - return the filter to an attribute type
 * @vue-event {void} getAgGridFloatingFilterFromAttributeType -
 * return the floating filter to an attribute type
 * @vue-event {void} getAgGridCellRendererFromAttributeType -
 * return the cell renderer to an attribute type
 * @vue-event {void} getAgGridWidthFromAttributeType -
 * return the with of column to an attribute type
 * @vue-event {void} onFilterChanged - called on filter changed tos et filterModel
 * @vue-event {void} showSegmentModalCreateOrEdit - called to show segment create or edit modal
 * @vue-event {void} onAttributeSaved - add new column after saved attribute
 * @vue-event {void} onSegmentSaved - called on segment save to refresh segment list
 * @vue-event {void} checkDNCContacts - called to check DNC on selected contacts
 */
export default {
  name: 'ContactList',
  components: {
    BaseAgGridHeader,
    ItemsPerPage,
    ListActionDropDown,
    ExportJsonToExcel,
    ContactListCreateOrEdit,
    AttributeCreateOrEdit,
    SegmentCreateOrEdit,
    ExportAlertInfo,
    ContactListImporter,
    SelectionAlertInfo,
    // eslint-disable-next-line vue/no-unused-components
    AgGridSelectionHeader,
    // Cell Renderer
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererTags,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererCategories,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererBooleans,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererDate,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererMarketingStatus,
    ContactListToolbar,
  },
  mixins: [commonTrashListCreateOrEditWithAgGrid],
  props: {
    selectedFromCampaign: {
      type: Boolean,
      required: false,
      default: false,
    },
    marketingStatusFilterOptions: {
      type: Array,
      required: false,
      validator(options) {
        return options.every(
          (option) => Object.values(enums.Contact.MarketingStatus).includes(option),
        );
      },
      default() {
        return Object.values(enums.Contact.MarketingStatus);
      },
    },
    marketingStatusFilterType: {
      type: String,
      required: false,
      validator(type) {
        return Object.values(enums.AppFilter.FilterType.Enum.Type).includes(type);
      },
      default: enums.AppFilter.FilterType.Enum.Type.ONE,
    },
    initialSegment: {
      type: [Object, String],
      required: false,
    },
  },
  data() {
    return {
      entity: this.$enums.Entity.CONTACT,
      exportCollectionFunction: (data) => this.exportContacts({
        exportInfo: data,
        trash: this.trash,
      }),
      deleteRecordFunction: (id) => this.removeContact({ contactId: id, trash: this.trash }),
      deleteRecordsFunction: (data) => this.removeContacts({ ...data, trash: this.trash }),
      restoreItemFunction: this.restoreContact,
      restoreItemsFunction: this.restoreContacts,

      dataSourceIsSet: false,
      insertedSelectedAudienceAttributes: false,
      selectedSegment: null,
      filtersChangedWithSegment: false,
      dontResetFiltersOnClearSegment: false,
      dontResetFiltersOnChangeFiltersMatch: false,
      filtersModel: null,
      allContactAttributes: [],
      newSegmentName: '',

      // modals
      activeModalAttributeCreateOrEdit: false,
      showCreateOrEditAttributeComponent: false,
      activeModalSegmentCreateOrEdit: false,
      showCreateOrEditSegmentComponent: false,
      titleModalAttribute: this.$t('$Modals.CreateModalTitle', {
        entity: this.$tc('$Entities.ContactAttribute', 1),
      }),
      titleModalSegment: '',
      segmentModalOperation: this.$enums.Operation.CREATE,

      baseColumnDefsCount: 6,
      columnDefs: [
        {
          colId: 'firstName',
          headerName: this.$t('$General.FirstName'),
          field: 'firstName',
          filter: 'agTextColumnFilter',
          minWidth: 100,
          maxWidth: 400,
          checkboxSelection: !this.selectedFromCampaign,
          headerComponentParams: { checkboxSelection: !this.selectedFromCampaign },
          requireOnImport: true,
          validateValueOnImport: (value) => firstOrLastNameRegex.test(value),
        },
        {
          colId: 'lastName',
          headerName: this.$t('$General.LastName'),
          field: 'lastName',
          filter: 'agTextColumnFilter',
          minWidth: 100,
          maxWidth: 400,
          validateValueOnImport: (value) => firstOrLastNameRegex.test(value),
        },
        {
          colId: 'email',
          headerName: this.$tc('$General.Email'),
          field: 'email',
          filter: 'agTextColumnFilter',
          minWidth: 100,
          maxWidth: 400,
          validateValueOnImport: (value) => emailRegex.test(value),
        },
        {
          colId: 'phoneInternational',
          headerName: this.$tc('$General.Phone'),
          field: 'phoneInternational',
          filter: 'agTextColumnFilter',
          minWidth: 100,
          maxWidth: 400,
          validationOnImport: this.$enums.ImportCollections.Validations.PHONE,
          requireOnImport: true,
          validateValueOnImport: (phone) => {
            try {
              const phoneNumber = parsePhoneNumber(phone, this.tenantDetailsCountry);

              return phoneNumber && phoneNumber.isValid();
            } catch (e) {
              return false;
            }
          },
        },
        {
          colId: 'marketingStatus',
          headerName: this.$t('$General.MarketingStatus'),
          field: 'marketingStatus',
          filter: 'agGridStatusFilter',
          floatingFilterComponent: 'agGridStatusFloatingFilter',
          filterParams: {
            filterInner: 'value',
            statusOptions: this.marketingStatusFilterOptions.map((status) => ({
              name: this.$t(`$Enums.Contact.MarketingStatus.${status}`),
              value: status,
            })),
            i18nPrefixStatus: '$Enums.Contact.MarketingStatus.',
          },
          minWidth: 100,
          maxWidth: 600,
          cellRendererFramework: 'CellRendererMarketingStatus',
          ignoreOnImport: true,
        },
        {
          colId: 'tags',
          headerName: this.$tc('$General.Tag', 2),
          field: 'tags',
          minWidth: 400,
          maxWidth: 600,
          cellRendererFramework: 'CellRendererTags',
          sortable: false,
          unSortIcon: false,
          filter: 'TagsDropDownFilter',
          filterParams: {
            buttons: ['reset', 'apply'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            alwaysShowBothConditions: false,
          },
          floatingFilterComponent: 'TagsFloatingFilter',
          ignoreOnImport: true,
        },
      ],
      components: {
        CellRendererActions,
        CellRendererTags,
        CellRendererDate,
        CellRendererMarketingStatus,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
        TagsFloatingFilter,
        TagsDropDownFilter,
        CategoriesDropDownFilter,
        CategoriesFloatingFilter,
        agGridBooleanFilter,
        agGridBooleanFloatingFilter,
        agGridStatusFilter,
        agGridStatusFloatingFilter,
      },
    };
  },
  computed: {
    ...mapGetters({
      tenantDetailsCountry: 'auth/tenantAccountDetailsCountry',
    }),
    allMultipleActionOptions() {
      if (this.trash) {
        return [
          ...this.trashMultipleActionOptions,
          ...this.defaultMultipleActionOptions,
        ];
      }

      return [
        ...this.trashMultipleActionOptions,
        ...this.defaultMultipleActionOptions,
      ];
    },
  },
  watch: {
    activeModalAttributeCreateOrEdit(val) {
      this.showCreateOrEditAttributeComponent = val;
    },
    activeModalSegmentCreateOrEdit(val) {
      this.showCreateOrEditSegmentComponent = val;
    },
    selectedSegment(val, oldVal) {
      if (val && val.filters && typeof val.filters === 'object') {
        const { filters, filtersMatch } = val;

        this.filtersMatch = filtersMatch;
        this.applyFilters(filters);
        this.filtersChangedWithSegment = false;
        this.dontResetFiltersOnChangeFiltersMatch = true;

        if (oldVal && oldVal.filters && typeof oldVal.filters === 'object') {
          const filtersToApply = this.getFiltersToApply(oldVal.filters);
          this.setBulkOperation(filtersToApply, this.setFiltersBulkOperationAmount || 1);
        }
      } else if (!this.dontResetFiltersOnClearSegment) {
        const model = this.gridApi.getFilterModel();
        if (model && typeof model === 'object' && Object.keys(model).length > 0) {
          this.resetFilters();
        }
      } else {
        this.dontResetFiltersOnClearSegment = false;
      }

      this.resetDataSourceAndSelection();
      this.$emit('segment-change', val);
    },
    filtersMatch() {
      if (!this.dontResetFiltersOnChangeFiltersMatch) {
        this.selectedSegment = null;
        this.filtersChangedWithSegment = false;
        this.dontResetFiltersOnClearSegment = true;
      } else {
        this.dontResetFiltersOnChangeFiltersMatch = false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.initSegment();
    }, 500);
  },
  methods: {
    ...mapActions({
      fetchAllContacts: 'contact/fetchAllContacts',
      fetchAllContactsFromTrash: 'contact/fetchAllContactsFromTrash',
      fetchAllAttributes: 'attribute/fetchAllAttributes',

      fetchSegment: 'segment/fetchSegment',

      exportContacts: 'contact/exportFile',

      removeContact: 'contact/removeContact',
      removeContacts: 'contact/removeContacts',
      restoreContact: 'contact/restoreContact',
      restoreContacts: 'contact/restoreContacts',
      updatePageTitle: 'updatePageTitle',
    }),
    async initSegment() {
      if (this.initialSegment) {
        if (typeof this.initialSegment === 'string') {
          this.selectedSegment = await this.fetchSegment(this.initialSegment);
        } else {
          this.selectedSegment = this.initialSegment || null;
        }
      }
    },
    resetFiltersContacts() {
      if (this.selectedSegment) {
        this.selectedSegment = null;
      } else {
        this.resetFilters();
      }
    },
    async setAgGridDataSource() {
      await this.fetchAudienceAttributes();

      if (!this.insertedSelectedAudienceAttributes) {
        this.insertAttributesColumns(this.allContactAttributes);
      }

      this.gridApi.setDatasource(this.dataSource);
      this.dataSourceIsSet = true;
    },
    async fetchAgGridData(params) {
      const populate = [
        { path: 'tags', select: 'id name' },
      ];

      if (this.trash) {
        return this.fetchAllContactsFromTrash({
          ...params,
          populate,
        });
      }

      let filters = {
        ...params.filter,
      };
      let { filtersMatch } = params;

      if (!filters.marketingStatus) {
        filters = {
          marketingStatus: {
            filterInner: 'value',
            filterType: this.$enums.AppFilterType.ENUM,
            type: this.marketingStatusFilterType,
            filter: this.marketingStatusFilterOptions,
          },
          agGridFilters: {
            filterType: this.$enums.AppFilterType.CONDITIONAL,
            filtersMatch: params.filtersMatch,
            filter: params.filters,
          },
        };

        filtersMatch = this.$enums.AppFilterMathType.ALL;
      }

      if ('phoneInternational' in params.filters) {
        const filter = {
          ...params.filters.phoneInternational,
          filter: params.filters.phoneInternational.filter.replace(/ /g, ''),
        };

        filters = {
          ...filters,
          phoneNumberFilters: {
            filterType: this.$enums.AppFilterType.CONDITIONAL,
            filtersMatch: this.$enums.AppFilterMathType.ANY,
            filter: {
              phoneInternationalSignificant: filter,
              phoneInternationalSignificantComplete: filter,
              phoneNationalSignificant: filter,
              phoneSignificant: filter,
            },
          },
        };

        if ('agGridFilters' in filters) {
          const { phoneInternational, ..._filters } = filters.agGridFilters.filter;
          filters.agGridFilters.filter = _filters;
        } else {
          const { phoneInternational, ..._filters } = filters;
          filters = _filters;
        }
      }

      return this.fetchAllContacts({
        ...params,
        filters,
        filtersMatch,
        populate,
      });
    },
    async fetchAudienceAttributes() {
      const resp = await this.fetchAllAttributes({});
      this.allContactAttributes = resp.data;
    },
    insertAttributesColumns(attributes = []) {
      if (attributes.length === 0) return;

      const agGridAttributeColumns = attributes.map((attr) => {
        let colDef = {
          colId: attr.id,
          headerName: attr.name,
          field: attr.id,
        };

        if (attr.type === this.$enums.Attributes.Type.DATE) {
          colDef = {
            ...colDef,
            type: 'dateColumn',
          };
        } else {
          colDef = {
            ...colDef,
            minWidth: this.getAgGridWidthFromAttributeType(attr.type, true),
            maxWidth: this.getAgGridWidthFromAttributeType(attr.type, false),
            filter: this.getAgGridFilterFromAttributeType(attr.type),
            floatingFilterComponent: this.getAgGridFloatingFilterFromAttributeType(attr.type),
            cellRendererFramework: this.getAgGridCellRendererFromAttributeType(attr.type),
          };

          if (attr.type === this.$enums.Attributes.Type.CATEGORY) {
            colDef = {
              ...colDef,
              sortable: false,
              unSortIcon: false,
              ignoreOnImport: true,
            };
          }
        }

        return colDef;
      });

      this.columnDefs = [
        ...this.columnDefs.slice(0, this.baseColumnDefsCount),
        ...agGridAttributeColumns,
        ...this.commonColumnDefs,
      ];
      this.insertedSelectedAudienceAttributes = true;
    },
    deleteAttributesColumns(attributes = 0) {
      this.columnDefs.splice(this.baseColumnDefsCount, attributes);
    },
    getAgGridFilterFromAttributeType(type = '') {
      switch (type) {
        case this.$enums.Attributes.Type.TEXT:
          return 'agTextColumnFilter';
        case this.$enums.Attributes.Type.NUMBER:
          return 'agNumberColumnFilter';
        case this.$enums.Attributes.Type.DATE:
          return 'agDateColumnFilter';
        case this.$enums.Attributes.Type.BOOL:
          return 'agGridBooleanFilter';
        case this.$enums.Attributes.Type.CATEGORY:
          return 'CategoriesDropDownFilter';

        default:
          return '';
      }
    },
    getAgGridFloatingFilterFromAttributeType(type = '') {
      switch (type) {
        case this.$enums.Attributes.Type.BOOL:
          return 'agGridBooleanFloatingFilter';
        case this.$enums.Attributes.Type.CATEGORY:
          return 'CategoriesFloatingFilter';
        default:
          return '';
      }
    },
    getAgGridCellRendererFromAttributeType(type = '') {
      switch (type) {
        case this.$enums.Attributes.Type.CATEGORY:
          return 'CellRendererCategories';
        case this.$enums.Attributes.Type.BOOL:
          return 'CellRendererBooleans';

        default:
          return '';
      }
    },
    getAgGridWidthFromAttributeType(type = '', min = true) {
      switch (type) {
        case this.$enums.Attributes.Type.TEXT:
          return min ? 200 : 400;
        case this.$enums.Attributes.Type.NUMBER:
          return min ? 100 : 200;
        case this.$enums.Attributes.Type.DATE:
          return min ? 260 : 260;
        case this.$enums.Attributes.Type.CATEGORY:
          return min ? 400 : 600;
        case this.$enums.Attributes.Type.BOOL:
          return min ? 200 : 400;

        default:
          return min ? 100 : 150;
      }
    },
    onFilterChanged() {
      if (this.setFiltersBulkOperation) {
        this.setFiltersBulkOperationCount += 1;
      }

      if (!this.setFiltersBulkOperation
        || this.setFiltersBulkOperationCount === this.setFiltersBulkOperationAmount) {
        const model = this.gridApi.getFilterModel();
        this.filtersAppliedCount = Object.keys(model).length;

        if (this.filtersAppliedCount > 0) {
          this.filtersModel = model;

          if (this.filtersChangedWithSegment) {
            this.selectedSegment = null;
            this.filtersChangedWithSegment = false;
            this.dontResetFiltersOnClearSegment = true;
          }

          if (this.selectedSegment && !this.filtersChangedWithSegment) {
            this.filtersChangedWithSegment = true;
          }
        } else {
          this.filtersModel = null;
          this.selectedSegment = null;
          this.filtersChangedWithSegment = false;
        }

        this.setSelectedRowsNone();

        if (this.setFiltersBulkOperation) {
          this.setFiltersBulkOperationCount = 0;
        }

        this.$emit('update:initial-filters', this.getMappedFilterModel());
      } else {
        this.filtersAppliedCount = Object.keys(this.gridApi.getFilterModel()).length;
        this.setSelectedRowsNone();
        this.$emit('update:initial-filters', this.getMappedFilterModel());
      }
    },
    showSegmentModalCreateOrEdit(operation = this.$enums.Operation.CREATE, segmentName = '') {
      if (operation !== this.$enums.Operation.CREATE
        && operation !== this.$enums.Operation.EDIT) return;

      this.titleModalSegment = `${operation} ${this.$tc('$Entities.Segment')}`;
      this.titleModalSegment = operation === this.$enums.Operation.CREATE
        ? this.$t('$Modals.CreateModalTitle', {
          entity: this.$tc('$Entities.Segment'),
        })
        : this.$t('$Modals.EditModalTitle', {
          entity: this.$tc('$Entities.Segment'),
          name: this.selectedSegment.name,
        });

      this.newSegmentName = segmentName;
      this.segmentModalOperation = operation;
      this.activeModalSegmentCreateOrEdit = true;
    },
    onAttributeSaved(attr) {
      if (!attr) return;

      this.fetchAudienceAttributes();

      const agGridAttributeColumn = {
        headerName: attr.name,
        field: attr.id,
        minWidth: this.getAgGridWidthFromAttributeType(attr.type, true),
        maxWidth: this.getAgGridWidthFromAttributeType(attr.type, false),
        filter: this.getAgGridFilterFromAttributeType(attr.type),
        floatingFilterComponent: this.getAgGridFloatingFilterFromAttributeType(attr.type),
        cellRendererFramework: this.getAgGridCellRendererFromAttributeType(attr.type),
      };

      this.columnDefs.splice(
        this.columnDefs.length - this.commonColumnDefs.length,
        0,
        agGridAttributeColumn,
      );
      this.gridApi.setColumnDefs(this.columnDefs);
      this.activeModalAttributeCreateOrEdit = false;
    },
    onSegmentSaved(segment) {
      if (this.segmentModalOperation === this.$enums.Operation.CREATE) {
        this.$refs.contactListToolbar.updateSelectSegments();
      }

      this.selectedSegment = segment;
      this.dontResetFiltersOnChangeFiltersMatch = true;
      this.activeModalSegmentCreateOrEdit = false;
    },
  },
};

</script>
