<template>
  <div>
    <ul class="flex flex-wrap justify-start text-sm">
      <li
        v-for="(option, index) in operatorsOptions"
        :key="index"
        class="mr-3">
        <vs-radio
          v-model="valueLocal"
          :disabled="disabled"
          :vs-value="option">
          {{ $t(`$AppFilters.$Operators.${option}`) }}
        </vs-radio>
      </li>
    </ul>
    <a
      v-if=" !disabled"
      href="#"
      class="inline-block text-primary flex text-sm link-plain"
      @click.prevent="$emit('remove')">
      <feather-icon
        icon="MinusCircleIcon"
        svgClasses="h-5 w-5 mr-1 hover:text-danger cursor-pointer"/>
      <span>
        {{ $t('RemoveSecondCondition') }}
      </span>
    </a>
  </div>
</template>

<script>
import filterMixin from '@/views/modules/segment/create-or-edit/_mixins/filter.mixin';

/**
 * Second condition header to filters on segment create or edit
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditSecondConditionHeader
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string[]} operatorsOptions - operators options for filters
 */
export default {
  name: 'SegmentListCreateOrEditSecondConditionHeader',
  i18n: {
    messages: {
      en: {
        RemoveSecondCondition: 'Remove condition',
      },
    },
  },
  mixins: [filterMixin],
  data() {
    return {
      operatorsOptions: Object.values(this.$enums.AppFilter.FilterOperator),
    };
  },
};
</script>

<style scoped>

</style>
