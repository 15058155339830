<template>
  <v-select
    ref="vSelect"
    v-model="valueLocal"
    class="w-full"
    :options="categoriesConditionOptions"
    :multiple="false"
    :clearable="false"
    close-on-select
    :getOptionLabel="(option) => this.$t(`$AppFilters.$Operation.${option}`)"
  />
</template>

<script>
import vSelect from 'vue-select';
import enums from '@/enums';

// Mixins
import filterMixin from '@/views/modules/segment/create-or-edit/_mixins/filter.mixin';

/**
 * conditions options for categories/tags filters
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditCategoriesTagsConditionsSelect
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} value - value
 * @vue-data {string[]} categoriesConditionOptions - categories/tags conditions options
 */
export default {
  name: 'SegmentListCreateOrEditCategoriesTagsConditionsSelect',
  components: {
    vSelect,
  },
  mixins: [filterMixin],
  props: {
    value: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(enums.AppFilter.FilterType.Categories.Type).includes(value);
      },
    },
  },
  data() {
    return {
      categoriesConditionOptions: Object.values(
        this.$enums.AppFilter.FilterType.Categories.Type,
      ),
    };
  },
};
</script>

<style scoped>

</style>
