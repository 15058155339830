<template>
  <div class="flex items-center">
    <vue-element-loading
      :active="fetchingContactMatchCount"
      color="rgba(var(--vs-primary), 1)"
      spinner="mini-spinner"/>

    <h1
      v-if="!hideCount"
      class="text-5xl text-primary font-bold d-inline-block">
      {{ contactsMatchCount }}
    </h1>

    <slot>
      <span class="ml-3">
        <a
          v-if="showContactsListLink && contactsMatchCount > 0"
          href="#"
          :class="contactsListLinkClasses"
          @click.prevent="activeModalViewContacts = true"
        >
          {{ countMessage || $tc('ContactsMatchMsg', contactsMatchCount) }}
        </a>
        <template v-else>
          {{ countMessage || $tc('ContactsMatchMsg', contactsMatchCount) }}
        </template>

        <template v-if="manageContactsLinks && contactsMatchCount === 0">
          <a
            href="#"
            class="link-popup"
            @click.prevent="$emit('create-contact')"
          >
            {{ $t('CreateFirstContactLink') }}
          </a> {{ $t('$General.Or') | lowercase }}
          <router-link
            to="/contacts"
            href="#"
            class="link-cta"
          >
            {{ $t('ManageYourContactsLink') }}
          </router-link>
        </template>
      </span>
    </slot>

    <feather-icon
      v-if="showRefresh"
      icon='RefreshCwIcon'
      svgClasses='h-5 w-5'
      class='text-primary cursor-pointer ml-3'
      @click="refreshCountContactsSegments"/>

    <vs-popup
      :title="$t('ViewContactsModalTitle')"
      :fullscreen="true"
      :active.sync="activeModalViewContacts">
      <transition name="zoom-fade">
        <contact-list
          v-if="activeModalViewContacts"
          :initial-filter-match="filtersMatch"
          :initial-filters="getMappedFilters(false)"
          :marketing-status-filter-options="marketingStatusFilterOptions"
          :readonly-list="true"
          :hide-actions-column="true"
          :selected-from-campaign="true"
        />
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import enums from '@/enums';

/**
 * Show numbers of contacts that match with segment filters
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditContactsMatchCount
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} filtersMatch - filters match from segment
 * @vue-prop {boolean} isThereAnyValidFilter - indicate if exist any valid filter
 * @vue-prop {Function} getValidFilters - function to get valid filters to get contacts
 * @vue-data {number} [contactsMatchCount=0] - number of contacts that match with segment filters
 * @vue-data {boolean} [fetchingContactMatchCount=false] - indicate if component is fetching data
 * @vue-data {string} [lastFiltersQueried=''] - last filters queried in json formatted
 * @vue-event {void} refreshCountContactsSegments - fetch & refresh count of contacts
 */
export default {
  name: 'SegmentListCreateOrEditContactsMatchCount',
  components: {
    ContactList: () => import('@/views/modules/contacts/ContactList.vue'),
  },
  i18n: {
    messages: {
      en: {
        ContactsMatchMsg: 'No contacts match the filters | contact matches filters | contacts match filters',
        CreateFirstContactLink: 'Create your first contact',
        ManageYourContactsLink: 'Manage your contacts',
        ViewContactsModalTitle: 'Contacts that match filters',
      },
    },
  },
  props: {
    filtersMatch: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(enums.AppFilter.FilterMathType).includes(value);
      },
    },
    isThereAnyValidFilter: {
      type: Boolean,
      required: true,
    },
    getValidFilters: {
      type: Function,
      required: true,
    },
    showRefresh: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedFromCampaign: {
      type: Boolean,
      required: false,
      default: false,
    },
    campaignMessageType: {
      type: String,
      required: false,
      validator(type) {
        return Object.values(enums.Campaign.MessageType).includes(type);
      },
    },
    countMessage: {
      type: String,
      required: false,
      default: '',
    },
    hideCount: {
      type: Boolean,
      required: false,
      default: false,
    },
    showContactsListLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    manageContactsLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
    contactsListLinkClasses: {
      type: String,
      required: false,
      default: 'link-popup',
    },
  },
  data() {
    return {
      contactsMatchCount: 0,
      fetchingContactMatchCount: false,
      lastFiltersQueried: '',

      activeModalViewContacts: false,
      filtersMatchToViewContacts: this.filtersMatch,
    };
  },
  computed: {
    marketingStatusFilterOptions() {
      const marketingStates = [
        this.$enums.Contact.MarketingStatus.SUBSCRIBED,
      ];

      return this.campaignMessageType
      === this.$enums.Campaign.MessageType.TRANSACTIONAL || this.campaignMessageType === undefined
        ? [
          ...marketingStates,
          this.$enums.Contact.MarketingStatus.UNSUBSCRIBED,
        ]
        : marketingStates;
    },
    marketingStatusFilter() {
      return {
        filterInner: 'value',
        filterType: this.$enums.AppFilterType.ENUM,
        type: this.$enums.AppFilter.FilterType.Enum.Type.ONE,
        filter: this.marketingStatusFilterOptions,
      };
    },
  },
  watch: {
    isThereAnyValidFilter() {
      this.refreshCountContactsSegments();
    },
    contactsMatchCount(newVal) {
      this.$emit('count-changed', newVal);
    },
  },
  mounted() {
    this.refreshCountContactsSegments();
  },
  methods: {
    ...mapActions({
      getCountContacts: 'contact/fetchCountContactsFilters',
    }),
    checkIfItHasCategories(filters, validFilters) {
      const newFilters = { ...filters };

      Object.keys(validFilters).forEach((filterKey) => {
        if (validFilters[filterKey].filterType === this.$enums.AppFilterType.CATEGORIES
          && Array.isArray(validFilters[filterKey].filter)) {
          const filter = {
            ...validFilters[filterKey],
            filter: validFilters[filterKey].filter.map((value) => (typeof value === 'object' ? value.id : value)),
          };

          if ('segmentFilters' in newFilters) {
            newFilters.segmentFilters.filter[filterKey] = filter;
          } else {
            newFilters[filterKey] = filter;
          }
        }
      });

      return newFilters;
    },
    checkIfItHasTags(filters, validFilters) {
      const newFilters = { ...filters };

      if ('tags' in validFilters && Array.isArray(validFilters.tags.filter)) {
        const filter = {
          ...validFilters.tags,
          filter: validFilters.tags.filter.map((value) => (typeof value === 'object' ? value.id : value)),
        };

        if ('segmentFilters' in newFilters) {
          newFilters.segmentFilters.filter.tags = filter;
        } else {
          newFilters.tags = filter;
        }
      }

      return newFilters;
    },
    checkIfItsSelectedFromCampaign(filters, filtersMatch) {
      if (this.selectedFromCampaign) {
        return {
          filters: {
            marketingStatus: this.marketingStatusFilter,
            segmentFilters: {
              filterType: this.$enums.AppFilterType.CONDITIONAL,
              filtersMatch: this.filtersMatch,
              filter: filters,
            },
          },
          filtersMatch: this.$enums.AppFilterMathType.ALL,
        };
      }

      return {
        filters,
        filtersMatch,
      };
    },
    checkIfItHasPhoneInternational(filters, validFilters) {
      let newFilters = filters;

      if ('phoneInternational' in validFilters) {
        const filter = {
          ...validFilters.phoneInternational,
          filter: validFilters.phoneInternational.filter.replace(/ /g, ''),
        };

        newFilters = {
          ...newFilters,
          phoneNumberFilters: {
            filterType: this.$enums.AppFilterType.CONDITIONAL,
            filtersMatch: this.$enums.AppFilterMathType.ANY,
            filter: {
              phoneInternationalSignificant: filter,
              phoneInternationalSignificantComplete: filter,
              phoneNationalSignificant: filter,
              phoneSignificant: filter,
            },
          },
        };

        if ('segmentFilters' in newFilters) {
          const { phoneInternational, ..._filters } = newFilters.segmentFilters.filter;
          newFilters.segmentFilters.filter = _filters;
        } else {
          const { phoneInternational, ..._filters } = newFilters;
          newFilters = _filters;
        }
      }

      return newFilters;
    },
    getMappedFilters(phone = true, tags = true, categories = true) {
      console.log('getMappedFilters', phone, tags, categories);
      const { filters } = this.getMappedFiltersAndFiltersMath(phone, tags, categories);

      return filters;
    },
    getMappedFiltersAndFiltersMath(phone = true, tags = true, categories = true) {
      const validFilters = this.getValidFilters(true);
      let filters = this.getValidFilters(true);
      const { filtersMatch } = this;

      if (validFilters) {
        if (phone) filters = this.checkIfItHasPhoneInternational(filters, validFilters);
        if (tags) filters = this.checkIfItHasTags(filters, validFilters);
        if (categories) filters = this.checkIfItHasCategories(filters, validFilters);
      }

      return {
        filters,
        filtersMatch,
      };
    },
    async refreshCountContactsSegments() {
      if (this.isThereAnyValidFilter) {
        try {
          this.fetchingContactMatchCount = true;
          let { filters, filtersMatch } = this.getMappedFiltersAndFiltersMath();
          ({ filters, filtersMatch } = this.checkIfItsSelectedFromCampaign(filters, filtersMatch));

          const jsonFilters = JSON.stringify(filters);

          if (filters && jsonFilters !== this.lastFiltersQueried) {
            this.lastFiltersQueried = jsonFilters;

            this.contactsMatchCount = await this.getCountContacts({
              filters,
              filtersMatch,
            });
            this.$emit('count-changed', this.contactsMatchCount);
          }
        } catch (e) {
          console.log(e);
        } finally {
          this.fetchingContactMatchCount = false;
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
