<template>
  <div class="filter-connector-wrapper">
      <span class="connector">
        {{
          (filtersMatch === $enums.AppFilter.FilterMathType.ALL
            ? $t('$General.And')
            : $t('$General.Or')) | uppercase
        }}
      </span>
  </div>
</template>

<script>
import enums from '@/enums';

/**
 * Filter connector for filters on segment create or edit
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditFilterConnector
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} value - value
 * @vue-data {string[]} booleansValuesOptions - boolean values for filters
 */
export default {
  name: 'SegmentListCreateOrEditFilterConnector',
  props: {
    filtersMatch: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(enums.AppFilter.FilterMathType).includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-connector-wrapper {
  position: relative;
  height: 6px;

  .connector {
    background: rgba(var(--vs-theme-background), 1);
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
    left: 50%;
    padding: 8px;
    position: absolute;
    top: -50px;
    height: 40px;
    min-width: 40px;
    text-align: center;
    transform: translate(-50%, 0px);
  }
}
</style>
