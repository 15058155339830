/**
 * This provides props and methods to list and manage trash resources
 * @mixin views/modules/mixins/commonTrashList
 *
 * @vue-prop {boolean} trash - indicate if list trash items or no
 * @vue-data {Function | null} fetchAllCollectionFunction - function to fetch all collection
 * @vue-data {Function | null} fetchAllCollectionTrashFunction -
 * function to fetch all collection form trash
 * @vue-data {Function | null} restoreItemFunction - function to restore an item for trash
 * @vue-data {Function | null} restoreItemsFunction - function to restore many items from trash
 * @vue-data {Function | null} afterRestoreRecordFunction - function to call after restore one item
 * @vue-data {Function | null} afterRestoreRecordsFunction -
 * function to call after restore many items
 * @vue-computed {Object[]} trashMultipleActionOptions -
 * additional multiple action options for trash list
 * @vue-event {Promise<Void>} fetchCollection - fetch primary data to list in the component
 * @vue-event {void} confirmRestoreRecord - show confirmation dialog to restore one item
 * @vue-event {void} restoreRecord - restore one item
 * @vue-event {void} showRestoreSuccess - show confirmation notify to restored item
 * @vue-event {void} confirmRestoreRecords - show confirmation dialog to restore many item
 * @vue-event {void} restoreRecords - restore many item and show notification
 */
export default {
  props: {
    trash: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fetchAllCollectionFunction: null,
      fetchAllCollectionTrashFunction: null,
      restoreItemFunction: null,
      restoreItemsFunction: null,
      afterRestoreRecordFunction: null,
      afterRestoreRecordsFunction: null,
    };
  },
  computed: {
    trashMultipleActionOptions() {
      return this.trash
        ? [{
          label: this.$t('$General.Restore'),
          event: 'restore',
          icon: 'RotateCcwIcon',
        }]
        : [];
    },
  },
  methods: {
    async fetchCollection(params) {
      if (typeof this.fetchAllCollectionTrashFunction !== 'function'
        || typeof this.fetchAllCollectionFunction !== 'function') {
        throw new Error('value must ve a valid function');
      }

      if (this.trash) {
        return this.fetchAllCollectionTrashFunction(params);
      }

      return this.fetchAllCollectionFunction(params);
    },
    async confirmRestoreRecord(record) {
      return new Promise((resolve) => {
        this.$showConfirmWarningDialog({
          title: this.$t('$Dialogs.ConfirmRestoreTitle'),
          text: this.$t('$Dialogs.ConfirmSpecificRestoreMsg', { entity: record.name }),
          accept: () => {
            this.restoreRecord(record.id);
            resolve(true);
          },
          cancel: () => resolve(false),
          acceptText: this.$t('$General.Restore'),
        });
      });
    },
    async restoreRecord(id) {
      if (typeof this.restoreItemFunction !== 'function') {
        throw new Error('value must ve a valid function');
      }

      this.$vs.loading({ type: 'radius' });
      await this.restoreItemFunction(id);

      if (typeof this.afterRestoreRecordFunction === 'function') {
        this.afterRestoreRecordFunction();
      }

      this.$vs.loading.close();
      this.showRestoreSuccess();
    },
    showRestoreSuccess() {
      this.$showSuccessActionNotification({
        title: this.$t('$Notifications.RestoredTitle', { entity: this.$tc(`$Entities.${this.entity}`) }),
        text: this.$tc('$Notifications.RestoredMsg', 1, { entity: this.$tc(`$Entities.${this.entity}`) }),
      });
    },
    confirmRestoreRecords({
      isAnyRowsSelected = false,
      rowsSelectedCount = 0,
      rowsSelected = null,
      filters = {},
    }) {
      if (isAnyRowsSelected) {
        this.$showConfirmWarningDialog({
          title: this.$t('$Dialogs.ConfirmRestoreTitle'),
          text: this.$tc('$Dialogs.ConfirmGeneralRestoreMsg', rowsSelectedCount, {
            count: rowsSelectedCount,
            entity: this.$tc(`$Entities.${this.entity}`, rowsSelectedCount),
          }),
          accept: () => this.restoreRecords({ rowsSelectedCount, rowsSelected, filters }),
          acceptText: this.$t('$General.Restore'),
        });
      } else {
        this.showNoSelectedRowsMessage();
      }
    },
    async restoreRecords({
      rowsSelectedCount = 0,
      rowsSelected = null,
      filters = {},
    }) {
      if (typeof this.restoreItemsFunction !== 'function') {
        throw new Error('value must ve a valid function');
      }

      this.$vs.loading({ type: 'radius' });
      await this.restoreItemsFunction({
        data: rowsSelected,
        filters,
      });
      this.$vs.loading.close();

      this.$showSuccessActionNotification({
        title: this.$t('$Notifications.RestoredTitle', {
          entity: this.$tc(`$Entities.${this.entity}`, rowsSelectedCount),
        }),
        text: this.$tc('$Notifications.RestoredMsg', rowsSelectedCount, {
          entity: this.$tc(`$Entities.${this.entity}`, rowsSelectedCount),
        }),
      });

      if (typeof this.afterRestoreRecordsFunction === 'function') {
        this.afterRestoreRecordsFunction();
      }
    },
  },
};
