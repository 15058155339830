<template>
  <div class="excel-import">
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      :name="fileInputName"
      :accept="acceptExtList"
      v-validate="`ext:${acceptExtListVeeValidate}|size:${maxFileSize}`"
      data-vv-validate-on="blur|input|change"
      :danger="errors.has(fileInputName)"
      :danger-text="errors.first(fileInputName)"
      @change="onInputFileChange">
    <div
      @click="onClickDragContainer"
      @drop="onDropContainer"
      @dragover="onDragoverContainer"
      @dragenter="onDragoverContainer"
      @dragleave="onDragLeaveContainer"
      :class="[
        'px-8',
        !minimal ? 'py-8' : 'py-2',
        'text-center', 'border-dashed', 'text-xl', 'border-2',
        borderDanger || errors.has(fileInputName) ? 'border-danger' : 'd-theme-border-grey-light',
        'd-theme-dark-bg',
        this.file === null ? 'cursor-pointer' : '',
        this.dragIn ? 'bg-drag-color' : ''
        ]">
      <template v-if="file === null">
        <feather-icon
          v-if="!minimal"
          icon="UploadCloudIcon"
          svgClasses="h-16 w-16 stroke-current text-grey"
          class="block" />
        <span>{{ $t('ContainerDropMsg', { type: this.fileType }) }} </span>
        <a
          href="#"
          class="font-medium text-primary link-cta"
          @click.prevent.stop="$refs.fileInput.click()">{{ $t('$General.Browse') | lowercase }}</a>
      </template>
      <template v-else>
        <feather-icon
          v-if="!minimal"
          icon="TrashIcon"
          svgClasses="h-16 w-16 stroke-current text-grey cursor-pointer"
          class="block"
          @click.prevent="removeFile" />
        <p>{{ this.file.name }}</p>
      </template>
    </div>
    <span
      v-show="errors.has(fileInputName)"
      class="text-danger text-sm">
      {{ errors.first(fileInputName) }}
    </span>
  </div>
</template>

<script>
/**
 * Component to upload files by drag and drop and convectional
 *
 * @module views/modules/components/UploadFile
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} [fileType=''] - file type text to show in the drop container
 * @vue-prop {Array<string>} acceptExt - list of accepted file extensions to upload
 * @vue-prop {boolean} [minimal=false] - indicate if show minimal or no
 * @vue-prop {boolean} [borderDanger=false] - indicate if show or no border danger
 * @vue-prop {number} [maxFileSize=5120] - max file size to upload
 * @vue-prop {string} [fileInputName='File'] - file input name for validations
 * @vue-data {File} [file=null] - file uploaded
 * @vue-data {boolean} [dragIn=false] - indicates if a drag is taking place inside the container
 * @vue-computed  {string} acceptExtList - comma separated list of accepted formats
 * @vue-computed  {string} acceptExtListVeeValidate -
 * comma separated list of accepted formats for vee validate
 * @vue-computed  {string} acceptExtListPatter - pipe separated list of accepted formats
 * without "." for use in regex
 * @vue-computed {string} maxFileSizeHumanReadable - Max file text transformed (KB, MB)
 * @vue-computed {number} maxFileSizeInBytes - Max file in Bytes
 * @vue-event {void} file - watch to emit file event with the updated file
 * @vue-event {void} onClickDragContainer - called on clicked drag container to
 * simulate click input file
 * @vue-event {void} onDragoverContainer - called on drag over container
 * @vue-event {void} onDragLeaveContainer - called on drag leave container
 * @vue-event {void} onDropContainer - called on drop container to check and assign file
 * @vue-event {void} onInputFileChange - called on change input file to check and assign file
 * @vue-event {void} checkFile - check the file and display error message if necessary
 * @vue-event {void} checkFileType - check the file extension and display error message if necessary
 * @vue-event {void} checkFileSize - check the file size and display error message if necessary
 * @vue-event {boolean} isAcceptFileType - indicates if the file extension is within
 * @vue-event {void} removeFile - remove uploaded file
 * @vue-event {void} resetFileInput - reset input file value to null
 * the accepted extensions
 */
export default {
  name: 'UploadFile',
  i18n: {
    messages: {
      en: {
        ContainerDropMsg: 'Drop {type} file or',
        UploadAttemptTitle: 'Upload attempt',
        UploadCountFailMsg: 'Only single file upload is supported',
        UploadExtensionsFailMsg: 'Only the upload of {acceptExtList} files is supported',
        $Validations: {
          Ext: 'The {fileName} field must be a valid file.',
          Size: 'The {fileName} size must be less than {maxFileSize}',
        },
      },
    },
  },
  props: {
    fileType: {
      type: String,
      required: false,
      default: '',
    },
    acceptExt: {
      type: Array,
      required: true,
      validator(extensions) {
        let valid = true;
        const patter = /\.([a-zA-Z]|[0-9])+/;

        extensions.forEach((ext) => {
          if (typeof ext !== 'string' || !patter.test(ext)) {
            valid = false;
          }
        });

        return valid;
      },
    },
    minimal: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderDanger: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxFileSize: {
      type: Number,
      required: false,
      default: 5120,
    },
    fileInputName: {
      type: String,
      required: false,
      default: 'File',
    },
  },
  data() {
    return {
      file: null,
      dragIn: false,
    };
  },
  computed: {
    acceptExtList() {
      return this.acceptExt.toString().replaceAll(',', ', ');
    },
    acceptExtListVeeValidate() {
      return this.acceptExt.toString().replaceAll('.', '');
    },
    acceptExtListPatter() {
      return this.acceptExt.toString().replace(/\./g, '').replace(/,/g, '|');
    },
    maxFileSizeHumanReadable() {
      if (this.maxFileSize > 1024) {
        const sizeInMB = Math.trunc(this.maxFileSize / 1024);
        return `${sizeInMB} MB`;
      }

      return `${this.maxFileSize} KB`;
    },
    maxFileSizeInBytes() {
      return this.maxFileSize * 1024;
    },
  },
  watch: {
    file(val) {
      this.$emit('file', val);
    },
  },
  methods: {
    onClickDragContainer() {
      if (this.file === null) {
        this.$refs.fileInput.click();
        this.$validator.reset();
      }
    },
    onDragoverContainer(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
      this.dragIn = true;
    },
    onDragLeaveContainer(e) {
      e.stopPropagation();
      e.preventDefault();
      this.dragIn = false;
    },
    onDropContainer(e) {
      e.stopPropagation();
      e.preventDefault();
      this.dragIn = false;
      const { files } = e.dataTransfer;

      if (files.length !== 1) {
        this.$showGeneralWarningNotification({
          title: this.$t('UploadAttemptTitle'),
          text: this.$t('UploadCountFailMsg'),
        });

        this.removeFile();
      } else {
        const rawFile = files[0];
        this.checkFile(rawFile);
      }
    },
    async onInputFileChange(e) {
      const { files } = e.target;
      const rawFile = files[0];
      await this.$validator.validate(this.fileInputName);
      this.checkFile(rawFile);
    },
    checkFile(rawFile) {
      const validType = this.checkFileType(rawFile);
      const validSize = this.checkFileSize(rawFile);

      if (validType && validSize) {
        this.file = rawFile;
      }
    },
    checkFileType(rawFile) {
      if (!this.isAcceptFileType(rawFile)) {
        this.$showGeneralWarningNotification({
          title: this.$t('UploadAttemptTitle'),
          text: this.$t('UploadExtensionsFailMsg', {
            acceptExtList: this.acceptExtList,
          }),
        });

        if (!this.errors.firstByRule(this.fileInputName, 'ext')) {
          this.$validator.errors.add({
            field: this.fileInputName,
            msg: this.$t('$Validations.Ext', { fileName: this.fileInputName }),
            rule: 'ext',
          });

          this.removeFile();
        }

        return false;
      }

      return true;
    },
    checkFileSize(rawFile) {
      if (rawFile.size > this.maxFileSizeInBytes) {
        if (!this.errors.firstByRule(this.fileInputName, 'size') && !this.errors.firstByRule(this.fileInputName, 'ext')) {
          this.$validator.errors.add({
            field: this.fileInputName,
            msg: this.$t('$Validations.Size', {
              fileName: this.fileInputName,
              maxFileSize: this.maxFileSizeHumanReadable,
            }),
            rule: 'size',
          });

          this.removeFile();
        }

        return false;
      }

      return true;
    },
    isAcceptFileType(file) {
      const patter = new RegExp(`\\.(${this.acceptExtListPatter})$`, 'i');
      return patter.test(file.name);
    },
    removeFile(e) {
      if (e !== undefined) {
        e.stopPropagation();
      }

      this.file = null;
      this.resetFileInput();
    },
    resetFileInput() {
      this.$refs.fileInput.value = null; // fix can't select the same excel
    },
  },
};
</script>

<!--todo: move this style to assets-->
<style lang="scss" scoped>
  .d-theme-dark-bg.bg-drag-color, .d-theme-dark-light-bg.bg-drag-color {
    background-color: #dae1e7 !important;
  }

  .theme-dark .d-theme-dark-bg.bg-drag-color {
    background-color: #414561 !important;
  }
</style>
