<template>
  <import-excel-to-json-card
    ref="card"
    :collapse-action="collapseAction"
    card-title-id="import-excel-to-json-card-1-title"
    :icon="confirmed ? 'check_box' : 'looks_one'"
    :color="confirmed ? 'success' : ''"
    :title="$t('CardTitle')"
    :subtitle="$t('CardSubTitle', {
        entity: $options.filters.lowercase($tc(`$Entities.${entity}`, 2))
      })"
    @toggle-collapse="$emit('toggle-collapse', $event)">
    <import-excel
      @loaded="loadDataInTable"
      @reset="resetFileLoaded"/>

    <div
      v-if="mappedFileData.length && fileHeaders.length"
      class="mt-3">
      <h5>
        {{ $t('PreviewTitle') }}
      </h5>
      <p>
        {{ $t('PreviewSubTitle', {
        fileName,
        rows: mappedFileData.length,
        columns: fileHeaders.length,
      }) }}
      </p>

      <vs-table
        stripe
        pagination
        :max-items="10"
        search
        :data="mappedFileData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th
            v-for="heading in fileHeaders"
            :key="heading"
            :sort-key="heading" >
            {{ heading }}
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indexTr) in data"
            :key="indexTr"
            :data="tr">
            <vs-td
              v-for="(valueCol, keyCol) in data[indexTr]"
              :key="keyCol + indexTr"
              :data="valueCol" >
              {{ valueCol }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div
      v-if="mappedFileData.length && fileHeaders.length"
      class="flex justify-center">
      <vs-button
        v-scroll-to="{ el: '#import-excel-to-json-card-1-title', offset: -100 }"
        class="ml-4 mt-2"
        color="primary"
        @click="confirmStep">
        {{ $t('ConfirmButtonText') }}
      </vs-button>
    </div>
  </import-excel-to-json-card>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue';
import ImportExcelToJsonCard from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonCard.vue';

import ImportExcelToJsonStepMixin from '@/views/modules/_components/import-exce-to-json/import-excel-to-json-step.mixin';

export default {
  name: 'ImportExcelToJsonStep1UploadFile',
  i18n: {
    messages: {
      en: {
        CardTitle: 'Upload your file',
        CardSubTitle: 'Select a file containing your {entity} to import.',
        PreviewTitle: 'File preview',
        PreviewSubTitle: 'You have just uploaded the file {fileName} with {rows} lines and {columns} columns.',
        ConfirmButtonText: 'Confirm your file',
      },
    },
  },
  components: {
    ImportExcelToJsonCard,
    ImportExcel,
  },
  mixins: [ImportExcelToJsonStepMixin],
  data() {
    return {
      sheetName: '',
      fileName: '',
      fileData: [],
    };
  },
  computed: {
    mappedFileDataLocal() {
      return this.fileData.map((row) => {
        const rowMapped = row;
        this.fileHeaders.forEach((header) => {
          rowMapped[header] = rowMapped[header] || null;
        });
        return rowMapped;
      });
    },
  },
  watch: {
    mappedFileDataLocal(val) {
      this.$emit('update:mapped-file-data', val);
    },
  },
  methods: {
    loadDataInTable({
      results, header, meta, fileName,
    }) {
      this.$emit('update:file-headers', header);
      this.fileData = results;
      this.sheetName = meta.sheetName;
      this.fileName = fileName;
    },
    resetFileLoaded() {
      this.$emit('refresh');
    },
    confirmStep() {
      this.$refs.card.toggleContent();
      this.$emit('confirmed');
    },
  },
};
</script>

<style scoped>

</style>
