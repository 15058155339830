<template>
  <div>
    <a
      href="#"
      class="inline-block text-primary flex text-sm link-plain"
      @click.prevent="$emit('add')">
      <feather-icon
        icon="PlusCircleIcon"
        svgClasses="h-5 w-5 mr-1 hover:text-success cursor-pointer"/>
      <span>
        {{ $t('AddSecondCondition') | lowercase }}
      </span>
    </a>
  </div>
</template>

<script>
/**
 * Component to add second conditions to filters
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditAddSecondCondition.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'SegmentListCreateOrEditAddSecondCondition',
  i18n: {
    messages: {
      en: {
        AddSecondCondition: 'Add second condition',
      },
    },
  },
};
</script>

<style scoped>

</style>
