<template>
  <div>
    <tags-drop-down-filter
      :class="{'border-danger': errors.has(fieldName)}"
      :disabled="disabled"
      :ag-grid-floating-filter="false"
      :pagination-page-size="5"
      :selected-tags.sync="valueLocal"
      manage-redirect-only-event
      @blur="$emit('blur', $event)"
      @redirect="(routeName) => $emit('redirect', routeName)">
    </tags-drop-down-filter>

    <vs-input
      :value="valueLocal.length > 0 ? 'valid' : ''"
      type="hidden"
      :name="fieldName"
      v-validate="'required'"
      data-vv-validate-on="input|blur"
      :danger="errors.has(fieldName)"
      :danger-text="errors.first(fieldName)"/>
  </div>

</template>

<script>
import TagsDropDownFilter from '@/views/modules/contacts/filters/TagsDropDownFilter';

// Mixins
import filterMixin from '@/views/modules/segment/create-or-edit/_mixins/filter.mixin';

/**
 * Tags filter for create or edit segment
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditTagsFilter
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'SegmentListCreateOrEditTagsFilter',
  components: {
    TagsDropDownFilter,
  },
  mixins: [filterMixin],
  props: {
    value: {
      type: [String, Array],
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
