/**
 * common data and methods to show items in ag grid cell renderer
 *
 * @mixin views/modules/mixins/commonCellRendererItems
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop  {Array.<Object>} items - entry items to show
 * @vue-prop  {number} showMaximum - number of maximum items to show
 * @vue-prop  {boolean} showClear - indicate if show or no clear button
 * @vue-prop  {Array.<Object>} itemsLocal - items to show
 * @vue-computed  {Array.<Object>} firstItems - first items to show
 * @vue-computed  {string.<Object>} lastItems - last items no showed
 * @vue-event  {void} items - watch to update itemsLocal
 * @vue-event  {void} created - hook to init itemsLocal
 */
export default {
  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return null;
      },
    },
    showMaximum: {
      type: Number,
      required: false,
      default: 3,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      itemsLocal: [],
    };
  },
  computed: {
    itemsLocalMapped() {
      return this.itemsLocal && Array.isArray(this.itemsLocal)
        ? this.itemsLocal.filter((item) => !!item && typeof item === 'object'
          && 'id' in item && 'name' in item)
        : [];
    },
    itemsLocalMappedLength() {
      return this.itemsLocalMapped && Array.isArray(this.itemsLocalMapped)
        ? this.itemsLocalMapped.length
        : 0;
    },
    firstItems() {
      if (this.itemsLocalMappedLength > this.showMaximum) {
        return this.itemsLocalMapped.slice(0, this.showMaximum);
      }

      return this.itemsLocalMapped;
    },
    lastItems() {
      if (this.itemsLocalMappedLength > this.showMaximum) {
        return this.itemsLocalMapped.slice(this.showMaximum);
      }

      return this.itemsLocalMapped;
    },
    lastItemsLength() {
      return this.lastItems && Array.isArray(this.lastItems)
        ? this.lastItems.length
        : 0;
    },
  },
  watch: {
    items(newVal) {
      this.itemsLocal = newVal === null ? this.params.value : newVal;
    },
    'params.value': function (items) {
      this.itemsLocal = items;
    },
  },
  created() {
    if (this.items === null) {
      this.itemsLocal = this.params && this.params.value
        ? this.params.value
        : [];
    } else {
      this.itemsLocal = this.items;
    }
  },
};
