<template>
  <div>
    {{ yesOrNo }}
  </div>
</template>

<script>

/**
 * Cell renderer to show boolean values
 *
 * @module views/modules/contacts/contact/ContactListCellRendererBooleans
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed  {string} yesOrNo - show string values depend on boolean value
 */
export default {
  name: 'ContactListCellRendererBooleans',
  computed: {
    yesOrNo() {
      if (this.params.value !== null && this.params.value !== undefined) {
        return this.params.value ? this.$t('$General.Yes') : this.$t('$General.No');
      }

      return '';
    },
  },
};
</script>
