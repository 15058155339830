/**
 * common props, data & methods for categories and tags filters
 *
 * @module views/modules/segment/create-or-edit/_mixins/categories-tags-filter.mixin.js
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} value - value for filter
 * @vue-prop {string} items - items options for filter
 */
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
