<template>
  <form @submit.prevent>
    <div class="vx-row mb-5">
      <div class="vx-col w-full">
        <filters-match
          v-model="model.filtersMatch"
          :disabled="isView"/>
      </div>
    </div>

    <div
      v-for="(f, index) in localSelectedFilters"
      :key="index"
      :class="[f.attribute ? 'vx-card bg-theme-background p-4 mb-base' : '']">
      <filter-connector
        v-if="index > 0 && f.attribute"
        :filters-match="model.filtersMatch"/>

      <div class="vx-row">
        <filter-selection
          v-model="f.attribute"
          class="vx-col w-full mb-1"
          :disabled="isView"
          :show-remove="!isView && localSelectedFilters.length > 1"
          :default-filter-options="defaultContactAttributes"
          :selected-filters-valid="localSelectedFiltersValid"
          @remove="removeFilter(index)"
          @input="(attribute) => attribute ? resetFilter(index) : () => {}"/>

        <template v-if="f.attribute && attributeIsInput(f.attribute.type)">
          <div :class="['vx-col', withFromAttributeType(f.attribute.type, 0, f.condition1)]">
            <input-conditions-select
              v-model="f.condition1"
              :filter-type="f.attribute.type"
              :field-name="`${f.attribute.name} condition1`"
              :disabled="isView"
              @input="incrementContactsMatchCountKey()"/>
          </div>

          <div :class="['vx-col', withFromAttributeType(f.attribute.type, 1, f.condition1)]">
            <filter-input-value
              :key="f.condition1"
              v-model="f.value1"
              class="w-full"
              :disabled="isView"
              :field-name="f.attribute.name"
              :filter-type="f.attribute.type"
              :condition="f.condition1"
              :in-range-position="1"
              @blur="incrementContactsMatchCountKey()"/>
          </div>

          <div
            v-if="inputAttributeHasSecondValue(f.condition1, f.attribute.type)"
            :class="['vx-col', withFromAttributeType(f.attribute.type, 2, f.condition1)]">
            <filter-input-value
              :key="f.condition1"
              v-model="f.value3"
              class="w-full"
              :disabled="isView"
              :field-name="f.attribute.name"
              :filter-type="f.attribute.type"
              :condition="f.condition1"
              :in-range-position="2"
              @blur="incrementContactsMatchCountKey()"/>
          </div>

          <second-condition-header
            v-if="f.attribute && f.attribute.type !== $enums.AppFilterType.DATE && f.operator"
            v-model="f.operator"
            :disabled="isView"
            class="vx-col w-full flex justify-between items-center mt-6 mb-1"
            @remove="removeOperatorToFilter(index)"
            @input="incrementContactsMatchCountKey()"/>

          <div
            v-if="f.attribute.type !== $enums.AppFilterType.DATE && f.operator"
            :class="['vx-col', withFromAttributeType(f.attribute.type, 0, f.condition2)]">
            <input-conditions-select
              v-model="f.condition2"
              :filter-type="f.attribute.type"
              :field-name="`${f.attribute.name} condition2`"
              :disabled="isView"
              @input="incrementContactsMatchCountKey()"/>
          </div>

          <div
            v-if="f.operator"
            :class="['vx-col', withFromAttributeType(f.attribute.type, 1, f.condition2)]">
            <filter-input-value
              :key="f.condition2"
              v-model="f.value2"
              class="w-full"
              :disabled="isView"
              :field-name="f.attribute.name"
              :filter-type="f.attribute.type"
              :condition="f.condition2"
              :in-range-position="1"
              :first-condition="false"
              @blur="incrementContactsMatchCountKey()"/>
          </div>

          <div
            v-if="f.operator && f.attribute.type === $enums.AppFilterType.NUMBER
            && f.condition2 && f.condition2 === $enums.AppFilterOperation.IN_RANGE"
            :class="['vx-col', withFromAttributeType(f.attribute.type, 2, f.condition2)]">
            <filter-input-value
              :key="f.condition2"
              v-model="f.value4"
              class="w-full"
              :disabled="isView"
              :field-name="f.attribute.name"
              :filter-type="f.attribute.type"
              :condition="f.condition2"
              :in-range-position="2"
              :first-condition="false"
              @blur="incrementContactsMatchCountKey()"/>
          </div>
        </template>

        <boolean-filter
          v-if="f.attribute && f.attribute.type === $enums.AppFilterType.BOOLEAN"
          v-model="f.value1"
          :field-name="f.attribute.name"
          class="vx-col w-full"/>

        <template
          v-if="f.attribute
            && (f.attribute.type === $enums.AppFilterType.CATEGORIES
            || f.attribute.type === $enums.AppFilterType.TAGS)">
          <div class="vx-col w-full md:w-1/2">
            <categories-tags-conditions-select
              v-model="f.condition1"
              :disabled="isView"/>
          </div>

          <div class="vx-col w-full md:w-1/2 mt-4 md:mt-0">
            <categories-filter
              v-if="f.attribute.type === $enums.AppFilterType.CATEGORIES"
              v-model="f.value1"
              :disabled="isView"
              :field-name="f.attribute.name"
              :items="f.attribute.items"/>

            <tags-filter
              v-else
              v-model="f.value1"
              :disabled="isView"
              :field-name="f.attribute.name"
              @blur="incrementContactsMatchCountKey()"
              @redirect="(routeName) => $emit('redirect', routeName)"/>
          </div>
        </template>

        <add-second-condition
          v-if="showAddSecondCondition(f)"
          class="vx-col w-full flex justify-between mt-2"
          @add="addOperatorToFilter(index)"/>
      </div>
    </div>

    <div class="flex justify-between items-center flex-wrap mt-5">
      <contacts-match-count
        :key="contactsMatchCountKey"
        :filters-match="model.filtersMatch"
        :get-valid-filters="mappedFilterToSave"
        :is-there-any-valid-filter="isThereAnyValidFilter"
        :show-refresh="!isView"
        :selected-from-campaign="selectedFromCampaign"
        :manage-contacts-links="selectedFromCampaign"
        :show-contacts-list-link="selectedFromCampaign"
        :campaign-message-type="campaignMessageType"
        @count-changed="$emit('contacts-count-changed', $event)"
        @create-contact="$emit('create-contact')"
      >
        <slot name="contacts-match-count-text">
        </slot>
      </contacts-match-count>

      <div v-if="$slots['contacts-count-right']">
        <slot
          name="contacts-count-right"
        />
      </div>
    </div>

    <vs-divider
      v-if="!selectedFromCampaign"
      class="my-5"/>

    <div
      v-if="!selectedFromCampaign"
      class="vx-row">
      <div class="vx-col w-full">
        <vs-input
          v-model="model.name"
          class="w-full"
          :class="{required: !isView}"
          :disabled="isView"
          :name="$t('$General.Name')"
          :label="$t('$General.Name')"
          v-validate="'required|max:100'"
          data-vv-validate-on="blur|input"
          :danger="errors.has($t('$General.Name'))"
          :danger-text="errors.first($t('$General.Name'))"
          val-icon-danger="clear"/>
      </div>
    </div>

    <base-form-footer-action-buttons
      v-if="!selectedFromCampaign"
      :hide-save="isView"
      :actions="mappedActions"
      @action="onAction"
      @save="saveSegment()"
      @cancel="$emit('close')">
      <template v-slot:divider>
        <vs-divider class="my-5"/>
      </template>
      <template
        v-if="isView"
        v-slot:cancel>
        {{ $t("$General.Close") }}
      </template>
    </base-form-footer-action-buttons>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { isEqual as _isEqual } from 'underscore';
import enums from '@/enums';

// Mixins
import singleCreateOrEdit from '@/views/modules/_mixins/singleCreateOrEdit';

// Constructor
import SegmentConstructor from '@/views/modules/segment/segment.constructor';

// Components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';
import SegmentListCreateOrEditFiltersMatch from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditFiltersMatch.vue';
import SegmentListCreateOrEditBooleanFilter from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditBooleanFilter.vue';
import CategoriesTagsConditionsSelect from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditCategoriesTagsConditionsSelect.vue';
import SegmentListCreateOrEditCategoriesFilter from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditCategoriesFilter.vue';
import SegmentListCreateOrEditTagsFilter from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditTagsFilter.vue';
import SegmentListCreateOrEditAddSecondCondition from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditAddSecondCondition.vue';
import SegmentListCreateOrEditFilterConnector from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditFilterConnector.vue';
import SegmentListCreateOrEditFilterSelection from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditFilterSelection.vue';
import SegmentListCreateOrEditInputConditionsSelect from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditInputConditionsSelect.vue';
import SegmentListCreateOrEditSecondConditionHeader from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditSecondConditionHeader.vue';
import SegmentListCreateOrEditFilterInputValue from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditFilterInputValue.vue';
import SegmentListCreateOrEditContactsMatchCount from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditContactsMatchCount.vue';

/**
 * Component to create or edit segments filters
 *
 * @module views/modules/audience/segment/SegmentCreateOrEdit
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} name - initial name for segment
 * @vue-prop {Object} filters - filters to save in the segment
 * @vue-prop {string} filtersMatch - initial filter match
 * @vue-prop {boolean} replaceFiltersOnEdit -
 * indicate if on edit prop filters must be replace the model payload filters
 * @vue-data {Object} [model={...}] - segment model to save
 * @vue-data {Function | null} [addItemFunction=null] - function to add one tag
 * @vue-data {Function | null} [editItemFunction=null] - function to edit one tag
 * @vue-data {Array.<Object>} defaultContactAttributes - default attributes of contacts
 * @vue-data {Object} defaultFilter - default filter model
 * @vue-data {Array.<Object>} localSelectedFilters - local filters on the segment
 * @vue-data {number} contactsMatchCountKey - key for contacts match component
 * @vue-computed  {boolean} verifyProperties - verify is form to save is valid
 * @vue-computed  {Object[]} localSelectedFiltersValid - filters with an valid attribute
 * @vue-computed  {boolean} isThereAnyValidFilter - indicate of exist any valid filter
 * @vue-computed  {boolean} additionalModelWasChanged - additional indication if model was changed
 * @vue-event {void} initComponent - init the component
 * @vue-event {Object[]} getAttributesFromFilters - get attributes from some filters
 * @vue-event {void} loadFilters - load entry filters
 * @vue-event {void} mappedFilterToSave - filters mapped to save
 * @vue-event {void} saveSegment - called to save the segment
 * @vue-event {Promise<boolean>} validateSegmentFiltersToSave -
 * determine if filters are valid to save
 * @vue-event {void} addFilter - add bew filter
 * @vue-event {boolean} attributeIsInput - indicate if an attribute should be a input
 * @vue-event {boolean} inputAttributeHasSecondValue -
 * indicate if the attribute can has a second condition
 * @vue-event {string} withFromAttributeType - return the with by attribute type
 * @vue-event {void} removeFilter - remove one filter
 * @vue-event {void} addOperatorToFilter - add an operator to one filter by index
 * @vue-event {void} removeOperatorToFilter - remove an operator from one filter by index
 * @vue-event {void} resetFilter - reset one filter by index
 * @vue-event {void} incrementContactsMatchCountKey - increment contacts match key
 */
export default {
  name: 'SegmentListCreateOrEdit',
  components: {
    BaseFormFooterActionButtons,
    FiltersMatch: SegmentListCreateOrEditFiltersMatch,
    BooleanFilter: SegmentListCreateOrEditBooleanFilter,
    CategoriesFilter: SegmentListCreateOrEditCategoriesFilter,
    TagsFilter: SegmentListCreateOrEditTagsFilter,
    AddSecondCondition: SegmentListCreateOrEditAddSecondCondition,
    FilterConnector: SegmentListCreateOrEditFilterConnector,
    FilterSelection: SegmentListCreateOrEditFilterSelection,
    InputConditionsSelect: SegmentListCreateOrEditInputConditionsSelect,
    SecondConditionHeader: SegmentListCreateOrEditSecondConditionHeader,
    FilterInputValue: SegmentListCreateOrEditFilterInputValue,
    ContactsMatchCount: SegmentListCreateOrEditContactsMatchCount,
    CategoriesTagsConditionsSelect,
  },
  mixins: [singleCreateOrEdit],
  props: {
    entity: {
      type: String,
      required: false,
      default: '',
    },
    selectedFromCampaign: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    filters: {
      type: Object,
      required: false,
      default: null,
    },
    filtersMatch: {
      type: String,
      required: false,
      default: enums.AppFilter.FilterMathType.ALL,
      validator(value) {
        return Object.values(enums.AppFilter.FilterMathType).includes(value);
      },
    },
    prioritizeFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    replaceFiltersOnEdit: {
      type: Boolean,
      default: false,
    },
    campaignMessageType: {
      type: String,
      required: false,
      validator(type) {
        return Object.values(enums.Campaign.MessageType).includes(type);
      },
    },
  },
  data() {
    return {
      model: new SegmentConstructor(
        this.filters,
        this.filtersMatch,
        this.operation,
        this.modelPayload,
        this.prioritizeFilters,
      ),
      addItemFunction: this.addSegment,
      editItemFunction: this.editSegment,
      defaultContactAttributes: [
        {
          name: this.$t('$General.FirstName'),
          id: 'firstName',
          type: this.$enums.AppFilterType.TEXT,
        },
        {
          name: this.$t('$General.LastName'),
          id: 'lastName',
          type: this.$enums.AppFilterType.TEXT,
        },
        {
          name: this.$tc('$General.Email'),
          id: 'email',
          type: this.$enums.AppFilterType.TEXT,
        },
        {
          name: this.$tc('$General.Phone'),
          id: 'phoneInternational',
          type: this.$enums.AppFilterType.TEXT,
        },
        {
          name: this.$tc('$General.Tag', 2),
          id: 'tags',
          type: this.$enums.AppFilterType.TAGS,
        },
        {
          name: this.$t('$General.CreatedAt'),
          id: 'createdAt',
          type: this.$enums.AppFilterType.DATE,
        },
        {
          name: this.$t('$General.UpdatedAt'),
          id: 'updatedAt',
          type: this.$enums.AppFilterType.DATE,
        },
      ],
      defaultFilter: {
        attribute: null,
        operator: null,
        condition1: null,
        condition2: null,
        value1: null,
        value2: null,
        value3: null,
        value4: null,
      },
      localSelectedFilters: [],
      contactsMatchCountKey: 1000,
      localSelectedFiltersChanged: false,
    };
  },
  computed: {
    verifyProperties() {
      return this.model.name !== '' && this.model.filters;
    },
    localSelectedFiltersValid() {
      return this.localSelectedFilters.filter((f) => f.attribute);
    },
    isThereAnyValidFilter() {
      return this.localSelectedFiltersValid.length > 0;
    },
    additionalModelWasChanged() {
      return (this.isCreation && this.isThereAnyValidFilter)
        || (this.isEdition && this.localSelectedFiltersChanged);
    },
    existLastDefaultLocalSelectedFilter() {
      if (this.localSelectedFilters.length === 0) {
        return false;
      }

      const lastFilter = this.localSelectedFilters[this.localSelectedFilters.length - 1];

      return _isEqual(lastFilter, this.defaultFilter);
    },
  },
  created() {
    this.initComponent();
  },
  watch: {
    localSelectedFilters() {
      this.localSelectedFiltersChanged = true;
    },
  },
  methods: {
    ...mapActions({
      fetchAllAttributes: 'attribute/fetchAllAttributes',
      addSegment: 'segment/addSegment',
      editSegment: 'segment/editSegment',
    }),
    async initComponent() {
      this.localSelectedFilters = [{
        ...this.defaultFilter,
      }];

      if (((this.isCreation || (this.isEdition && this.replaceFiltersOnEdit)) && this.filters)
        || ((this.isEdition || this.isView || this.isCloning) && this.model.filters)) {
        await this.loadFilters((this.isCreation || (this.isEdition && this.replaceFiltersOnEdit))
        && this.filters
          ? this.filters
          : this.model.filters);
      }

      if (this.name && this.isCreation) {
        this.model.name = this.name;
      }

      this.localSelectedFiltersChanged = false;
    },
    async getAttributesFromFilters(filters) {
      const filtersKeys = Object.keys(filters).filter((key) => key !== '_id' && key !== 'id');
      const filtersKeysNotFoundInDefaultsAttributes = filtersKeys.filter(
        (key) => !this.defaultContactAttributes.find((attr) => attr.id === key),
      );

      const resp = await this.fetchAllAttributes({
        filters: {
          _id: {
            filterType: this.$enums.AppFilterType.ENUM,
            type: this.$enums.AppFilter.FilterType.Enum.Type.ONE,
            filter: filtersKeysNotFoundInDefaultsAttributes,
          },
        },
      });

      const attributesFromDefault = this.defaultContactAttributes.filter(
        (attr) => filtersKeys.find((key) => attr.id === key),
      );
      const attributesFromServer = resp.data;

      return [
        ...attributesFromDefault,
        ...attributesFromServer,
      ];
    },
    async loadFilters(filters) {
      this.$vs.loading({ type: 'radius' });
      this.localSelectedFilters = [];
      const attributes = await this.getAttributesFromFilters(filters);

      Object.keys(filters).forEach((key) => {
        const newFilter = { ...this.defaultFilter };
        let error = false;

        if (key !== '_id' && key !== 'id') {
          const attribute = attributes.find(
            (attr) => attr.id === key,
          );

          newFilter.attribute = attribute;

          switch (attribute.type) {
            case this.$enums.AppFilterType.TEXT:
            case this.$enums.AppFilterType.NUMBER:

              if (filters[key].operator) {
                newFilter.operator = filters[key].operator;
                newFilter.condition1 = filters[key].condition1.type;
                newFilter.value1 = filters[key].condition1.filter;
                newFilter.condition2 = filters[key].condition2.type;
                newFilter.value2 = filters[key].condition2.filter;

                if (attribute.type === this.$enums.AppFilterType.NUMBER) {
                  newFilter.value3 = filters[key].condition1.filterTo;
                  newFilter.value4 = filters[key].condition2.filterTo;
                }
              } else {
                newFilter.condition1 = filters[key].type;
                newFilter.value1 = filters[key].filter;

                if (attribute.type === this.$enums.AppFilterType.NUMBER) {
                  newFilter.value3 = filters[key].filterTo;
                }
              }
              break;
            case this.$enums.AppFilterType.BOOLEAN:
              newFilter.value1 = filters[key].filter;
              break;
            case this.$enums.AppFilterType.DATE:
              newFilter.condition1 = filters[key].type;

              if (filters[key].type === this.$enums.AppFilterOperation.IN_RANGE) {
                newFilter.value1 = [
                  filters[key].dateFrom ? moment.utc(filters[key].dateFrom).toDate() : null,
                  filters[key].dateTo ? moment.utc(filters[key].dateTo).toDate() : null,
                ];
              } else {
                newFilter.value1 = filters[key].dateFrom ? moment.utc(filters[key].dateFrom).format('YYYY-MM-DD') : null;
              }
              break;
            case this.$enums.AppFilterType.CATEGORIES:
            case this.$enums.AppFilterType.TAGS:
              newFilter.condition1 = filters[key].type;
              newFilter.value1 = filters[key].filter;
              break;
            default:
              error = true;
          }

          if (!error) {
            this.localSelectedFilters.push(newFilter);
          }
        }
      });

      this.localSelectedFilters = [
        ...this.localSelectedFilters,
        { ...this.defaultFilter },
      ];

      this.$vs.loading.close();
    },
    mappedFilterToSave(mapsIds = false) {
      let filters = null;
      if (this.localSelectedFilters.some((f) => f.attribute)) {
        filters = {};

        this.localSelectedFiltersValid.forEach((currentValue) => {
          const filterKey = currentValue.attribute.id;
          filters[filterKey] = {};
          filters[filterKey].filterType = currentValue.attribute.type;

          switch (currentValue.attribute.type) {
            case this.$enums.AppFilterType.TEXT:
            case this.$enums.AppFilterType.NUMBER:
              // eslint-disable-next-line no-case-declarations
              const condition1 = {
                filterType: currentValue.attribute.type,
                type: currentValue.condition1,
                filter: currentValue.attribute.type === this.$enums.AppFilterType.NUMBER
                  ? (parseInt(currentValue.value1, 10) || '')
                  : currentValue.value1,
              };

              if (currentValue.attribute.type === this.$enums.AppFilterType.NUMBER
                && currentValue.condition1 === this.$enums.AppFilterOperation.IN_RANGE) {
                condition1.filterTo = currentValue.condition1
                === this.$enums.AppFilterOperation.IN_RANGE
                  ? currentValue.value3
                  : null;
              }

              if (currentValue.operator) {
                filters[currentValue.attribute.id].condition1 = {
                  ...condition1,
                };

                filters[currentValue.attribute.id].condition2 = {
                  filterType: currentValue.attribute.type,
                  type: currentValue.condition2,
                  filter: currentValue.value2,
                };

                if (currentValue.attribute.type === this.$enums.AppFilterType.NUMBER) {
                  // eslint-disable-next-line max-len
                  filters[currentValue.attribute.id].condition2.filterTo = currentValue.condition1
                  === this.$enums.AppFilterOperation.IN_RANGE
                    ? currentValue.value4
                    : null;
                }

                filters[currentValue.attribute.id].operator = currentValue.operator;
              } else {
                filters[currentValue.attribute.id] = {
                  ...condition1,
                };
              }

              break;
            case this.$enums.AppFilterType.BOOLEAN:
              filters[currentValue.attribute.id].filter = currentValue.value1;
              break;
            case this.$enums.AppFilterType.CATEGORIES:
            case this.$enums.AppFilterType.TAGS:
              filters[currentValue.attribute.id].type = currentValue.condition1;
              filters[currentValue.attribute.id].filter = mapsIds
              && Array.isArray(currentValue.value1)
                ? currentValue.value1.map((value) => value.id)
                : currentValue.value1;
              break;
            case this.$enums.AppFilterType.DATE:
              filters[currentValue.attribute.id].type = currentValue.condition1;

              if (currentValue.condition1 === this.$enums.AppFilterOperation.IN_RANGE) {
                // eslint-disable-next-line prefer-destructuring
                filters[currentValue.attribute.id].dateFrom = currentValue.value1[0];
                // eslint-disable-next-line prefer-destructuring
                filters[currentValue.attribute.id].dateTo = currentValue.value1[1];
              } else {
                filters[currentValue.attribute.id].dateFrom = currentValue.value1;
              }

              break;
            default:
              delete filters[filterKey];
          }
        });
      }

      return filters;
    },
    async saveSegment(name = '') {
      const filtersAreValid = await this.validateSegmentFiltersToSave();

      if (filtersAreValid) {
        this.model.name = name || this.model.name;
        this.model.filters = this.mappedFilterToSave();
        return this.save(this.model.toSavePayload());
      }

      return null;
    },
    validateSegmentFiltersToSave() {
      return new Promise((resolve, reject) => {
        const childrenToValidate = this.$children.filter((child) => child.$el.classList.contains('filter-input-value'));
        const validationArray = childrenToValidate.map((child) => child.$validator.validateAll());

        Promise.all(validationArray).then((validationResult) => {
          if (validationResult.every((result) => result)) {
            resolve(true);
          } else {
            const index = validationResult.findIndex((result) => !result);

            if (index !== -1) {
              const $el = childrenToValidate[index].$el.querySelector(`[name='${childrenToValidate[index].$validator.errors.items[0].field}']`) || childrenToValidate[index].$el;

              if ($el) {
                if ($el instanceof HTMLInputElement) {
                  $el.focus();
                } else {
                  $el.scrollIntoView(false);
                }
              }
            }

            resolve(false);
          }
        }).catch((err) => {
          reject(err);
        });
      });
    },
    showAddSecondCondition(filter) {
      return !this.isView
        && filter.attribute
        && (filter.attribute.type === this.$enums.AppFilterType.TEXT
          || filter.attribute.type === this.$enums.AppFilterType.NUMBER)
        && !filter.operator;
    },
    attributeIsInput(type) {
      return type === this.$enums.AppFilterType.TEXT
        || type === this.$enums.AppFilterType.DATE
        || type === this.$enums.AppFilterType.NUMBER;
    },
    inputAttributeHasSecondValue(condition, type) {
      return (type === this.$enums.AppFilterType.NUMBER)
        && condition && condition === this.$enums.AppFilterOperation.IN_RANGE;
    },
    withFromAttributeType(type = '', col = -1, condition = '') {
      switch (type) {
        case this.$enums.AppFilterType.TAGS:
        case this.$enums.AppFilterType.TEXT:
        case this.$enums.AppFilterType.CATEGORIES:
        case this.$enums.AppFilterType.DATE:
          return `w-full md:w-1/2 ${col > 0 ? 'mt-4 md:mt-0' : ''}`;
        case this.$enums.AppFilterType.NUMBER:
          if (condition && condition === this.$enums.AppFilterOperation.IN_RANGE && col !== -1) {
            return `w-full md:w-1/3 ${col > 0 ? 'mt-4 md:mt-0' : ''}`;
          }

          return `w-full md:w-1/2 ${col > 0 ? 'mt-4 md:mt-0' : ''}`;
        default:
          return 'w-full';
      }
    },
    removeFilter(index) {
      this.localSelectedFilters.splice(index, 1);
      this.incrementContactsMatchCountKey();
    },
    addOperatorToFilter(index, operator = this.$enums.AppFilter.FilterOperator.AND) {
      const filter = { ...this.localSelectedFilters[index] };
      filter.operator = operator;
      filter.condition2 = this.$enums.AppFilterOperation.EQUALS;
      this.localSelectedFilters.splice(index, 1, filter);
    },
    removeOperatorToFilter(index) {
      const filter = { ...this.localSelectedFilters[index] };
      filter.operator = null;
      filter.condition2 = this.$enums.AppFilterOperation.EQUALS;
      filter.value2 = null;
      filter.value4 = null;
      this.localSelectedFilters.splice(index, 1, filter);
      this.incrementContactsMatchCountKey();
    },
    resetFilter(index) {
      if (this.existLastDefaultLocalSelectedFilter) {
        return;
      }

      const filter = { ...this.localSelectedFilters[index] };

      filter.operator = null;
      filter.condition1 = this.$enums.AppFilterOperation.EQUALS;
      filter.condition2 = this.$enums.AppFilterOperation.EQUALS;
      filter.value1 = null;
      filter.value2 = null;
      filter.value3 = null;
      filter.value4 = null;

      if (filter.attribute) {
        switch (filter.attribute.type) {
          case this.$enums.AppFilterType.BOOLEAN:
            filter.condition1 = null;
            filter.condition2 = null;
            filter.value1 = this.$enums.AppFilter.FilterType.Boolean.Values.YES;
            break;
          case this.$enums.AppFilterType.TAGS:
          case this.$enums.AppFilterType.CATEGORIES:
            filter.condition1 = this.$enums.AppFilterOperation.ALL;
            filter.condition2 = null;
            filter.value1 = [];
            break;
          default:
        }
      }

      this.localSelectedFilters.splice(index, 1, filter);
      this.localSelectedFilters = [
        ...this.localSelectedFilters,
        { ...this.defaultFilter },
      ];
    },
    incrementContactsMatchCountKey() {
      this.contactsMatchCountKey += 1;
      this.$emit('filters-changed');
    },
  },
};
</script>
