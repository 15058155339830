<template>
  <div>
    <div
      v-if="valueLocal"
      class="flex items-center justify-between mb-1">
      <label
        class="vs-input--label">
        {{ valueLocal.name }}
      </label>
      <a
        v-if="showRemove"
        href="#"
        class="inline-block text-primary flex text-sm link-plain"
        @click.prevent="$emit('remove')">
        <feather-icon
          icon="MinusCircleIcon"
          svgClasses="h-5 w-5 mr-1 hover:text-danger cursor-pointer"/>
        <span>
          {{ $t('RemoveFilter') }}
        </span>
      </a>
    </div>

    <v-select-server
      v-else
      v-model="valueLocal"
      :disabled="disabled"
      class="w-full"
      label="name"
      :multiple="false"
      :close-on-select="true"
      :placeholder="$t('SelectAttributePlaceholder')"
      :fetch-function="fetchAllAttributes"
      manage-redirect-only-event
      manage-route-name="custom-fields"
      :manage-text="$t('ManageCustomAttributes')"
      :clearable="true"
      :pagination-page-size="5"
      :default-options="defaultFilterOptions"
      :selectable="attributeOptionIsSelectable"
      @redirect="(routeName) => $emit('redirect', routeName)"
      @input="$emit('input', $event)">
    </v-select-server>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

// Components
import VSelectServer from '@/views/modules/_components/v-select-server/VSelectServer.vue';

// Mixins
import filterMixin from '@/views/modules/segment/create-or-edit/_mixins/filter.mixin';

/**
 * Filter selection select on create or edit segment
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditFilterSelection
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} showRemove - indicate if show remove link
 * @vue-prop {Object[]} defaultFilterOptions - default filters options to select
 * @vue-prop {Object[]} selectedFiltersValid - selected filters valid
 * @vue-event {void} attributeOptionIsSelectable -
 * indicate if one attribute is selectable as a filter
 */
export default {
  name: 'SegmentListCreateOrEditFilterSelection',
  i18n: {
    messages: {
      en: {
        RemoveFilter: 'Remove filter',
        SelectAttributePlaceholder: 'Add a filter',
        ManageCustomAttributes: 'Manage custom attributes',
      },
    },
  },
  components: {
    VSelectServer,
  },
  mixins: [filterMixin],
  props: {
    showRemove: {
      type: Boolean,
      required: true,
    },
    defaultFilterOptions: {
      type: Array,
      required: true,
    },
    selectedFiltersValid: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchAllAttributes: 'attribute/fetchAllAttributes',
    }),
    attributeOptionIsSelectable(option) {
      return !this.selectedFiltersValid.map((f) => f.attribute.id).includes(option.id);
    },
  },
};
</script>

<style scoped>

</style>
