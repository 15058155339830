var render = function render(){var _vm=this,_c=_vm._self._c;return _c('import-excel-to-json-card',{ref:"card",class:{'opacity-50': !_vm.confirmedPreviousStep},attrs:{"collapse-action":_vm.collapseAction,"collapsed":true,"card-title-id":"import-excel-to-json-card-3-title","icon":_vm.confirmed ? 'check_box' : 'looks_3',"color":_vm.confirmed ? 'success' : '',"title":_vm.$t('CardTitle'),"subtitle":_vm.$t('CardSubTitle')}},[_c('div',[_c('vs-table',{attrs:{"stripe":"","pagination":"","max-items":10,"search":"","data":_vm.mappedImportData},scopedSlots:_vm._u([{key:"default",fn:function({data}){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},_vm._l((data[indextr]),function(col,indexcol){return _c('vs-td',{key:indexcol + col,attrs:{"data":col}},[_vm._v(" "+_vm._s(col)+" ")])}),1)})}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"w-full md:w-auto"},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('PreviewTitle'))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('PreviewSubTitle', {
                columns: _vm.countImportedHeader,
                rows: _vm.mappedImportData.length,
              }))}})])]),_c('template',{slot:"thead"},_vm._l((_vm.mappedImportHeaders),function(header){return _c('vs-th',{key:header.field,attrs:{"sort-key":header.field}},[_vm._v(" "+_vm._s(header.headerName)+" ")])}),1)],2),(_vm.defaultAttributes.length > 0)?_c('div',{staticClass:"my-6"},[_c('div',[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('DefaultAttributesTitle'))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('DefaultAttributesSubTitle', {
                attributes: this.defaultAttributes.map(
                  (attr) => attr.headerName
                  ).toString().replace(/,/g, ', '),
                }))}})]),_c('div',{staticClass:"mt-5"},[_vm._t("default-attributes")],2)]):_vm._e(),_c('div',[_vm._t("additional-options")],2),_c('div',{staticClass:"flex justify-center"},[_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"disabled":!_vm.importConfirmIsValid,"color":"primary"},on:{"click":_vm.confirmStep}},[_vm._v(" "+_vm._s(_vm.$t('ConfirmButtonText'))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }