<template>
  <vx-card
    ref="card"
    :collapse-action="collapseAction"
    :collapsed="collapsed"
    class="mt-3"
    @toggle-collapse="$emit('toggle-collapse', $event)">
    <template v-slot:header>
      <h4
        :id="cardTitleId"
        class="flex items-center">
        <vs-icon
          :icon="icon"
          :color="color"
          size="1.5rem"/>
        <span>
            {{ title }}
          </span>
      </h4>
      <h6 class="text-content hidden md:block">
        {{ subtitle }}
      </h6>
    </template>

    <slot></slot>
  </vx-card>
</template>

<script>
export default {
  name: 'ImportExcelToJsonCard',
  props: {
    collapseAction: {
      type: Boolean,
      required: true,
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardTitleId: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggleContent() {
      if (this.$refs.card) {
        this.$refs.card.toggleContent();
      }
    },
    isContentCollapsed() {
      return this.$refs && this.$refs.card && this.$refs.card.isContentCollapsed;
    },
  },
};
</script>

<style scoped>

</style>
