<template>
  <div>
    <import-excel-to-json-card
      ref="card"
      :collapse-action="collapseAction"
      :collapsed="true"
      :class="{'opacity-50': !confirmedPreviousStep}"
      card-title-id="import-excel-to-json-card-2-title"
      :icon="confirmed ? 'check_box' : 'looks_two'"
      :color="confirmed ? 'success' : ''"
      :title="$t('CardTitle')"
      :subtitle="$t('CardSubTitle')"
      @toggle-collapse="$emit('toggle-collapse', $event)">
      <div class="table-mapping">
        <mq-layout mq="mobile">
          <div class="cols-table-mapping">
            <div
              v-for="(item, index) in fileHeadersLocal"
              :key="index"
              :title="item"
              class="vx-row pr-0">
              <div
                class="vx-col w-full pr-0"
                style="display:flex; flex-direction: column; align-items: center">

                <div class="import-mobile-step2-row-main mobile-step2-mapping-header">
                  <div class="import-mobile-step2-title">
                    {{ $t('MappingFileHeader') }} :
                  </div>
                  <div class="import-mobile-step2-contents">
                    <p class="mb-4"><b>{{ item }}</b></p>
                  </div>
                </div>

                <div class="import-mobile-step2-row-main mobile-step2-mapping-data">
                  <div class="import-mobile-step2-title">
                    {{ $t('MappingDataHeader') }} :
                  </div>
                  <div class="import-mobile-step2-contents">
                    <p
                      v-for="(item2, index2) in getSliceItemFromHeader(item, 3)"
                      :key="index2">
                      {{ item2 }}
                    </p>
                  </div>
                </div>

                <div class="w-full import-mobile-step2-row-main mobile-step2-mapping-entity mt-3">
                  <div class="import-mobile-step2-contents">
                    <select-attribute
                      v-model="targetHeadersLocal[index]"
                      :index="index"
                      :enable-disabled="false"
                      :entity="entity"
                      :file-headers="fileHeadersLocal"
                      :target-headers="targetHeaders"
                      :target-headers-local="targetHeadersLocal"
                      :create-attributes="createAttributes"
                      :do-not-import-option="doNotImportOption"
                      :is-field-mapped="isFieldMapped"
                      :get-header-name-by-field-from-target-headers="
                          getHeaderNameByFieldFromTargetHeaders"
                      @add-attribute="onAddAttribute(index)"
                    />
                  </div>
                </div>

              </div>
              <vs-divider class="mt-5 mb-5"></vs-divider>
            </div>
          </div>
        </mq-layout>

        <mq-layout mq="tablet+">
          <div class="vx-row mt-3 cols-table-mapping">
            <div class="vx-col w-1/3 pr-0">
              <h5 class="mb-2">
                {{ $t('MappingFileHeader') }}
              </h5>

              <draggable
                :list="fileHeadersLocal"
                class="cursor-move"
                @change="fileHeadersChanged">
                <div
                  v-for="(item, index) in fileHeadersLocal"
                  :key="index"
                  :title="item">
                  <div class="list-draggable-item-import pr-6">
                    <i class="fas fa-sort hover:text-primary cursor-move mr-3"></i>
                    <p>{{ item }}</p>
                  </div>
                  <vs-divider class="m-0"></vs-divider>
                </div>
              </draggable>
            </div>

            <div class="vx-col w-1/3 px-0">
              <h5 class="mb-2">
                {{ $t('MappingDataHeader') }}
              </h5>

              <div
                v-for="(item, index1) in fileHeadersLocal"
                :key="index1"
                :title="item">
                <div class="list-draggable-item-import pr-6">
                  <div>
                    <p
                      v-for="(item2, index2) in getSliceItemFromHeader(item, 3)"
                      :key="index2">
                      {{ item2 }}
                    </p>
                  </div>
                </div>
                <vs-divider class="m-0"></vs-divider>
              </div>
            </div>

            <div class="vx-col w-1/3 pl-0">
              <h5 class="mb-2" style="white-space: nowrap">
                {{ $t('MappingEntityHeader', {
                entity: $tc(`$Entities.${entity}`)
              }) }}
              </h5>

              <draggable
                :list="targetHeadersLocal"
                class="cursor-move"
                @change="targetHeaderChanged">
                <div
                  v-for="(item, index) in targetHeadersLocal"
                  :key="index">
                  <div class="list-draggable-item-import" style="overflow-y: visible !important">
                    <div class="flex items-center w-full">
                      <i class="fas fa-sort hover:text-primary cursor-move mr-3"></i>
                      <div class="w-full">
                        <p
                          v-if="item.required
                          && !(item.validation
                          && item.validation === $enums.ImportCollections.Validations.PHONE
                          )"
                          :class="{'text-danger': index >= fileHeadersLocal.length}">
                          {{ `${item.headerName}*` }}
                        </p>
                        <select-attribute
                          v-else
                          v-model="targetHeadersLocal[index]"
                          :index="index"
                          :enable-disabled="true"
                          :entity="entity"
                          :file-headers="fileHeadersLocal"
                          :target-headers="targetHeaders"
                          :target-headers-local="targetHeadersLocal"
                          :create-attributes="createAttributes"
                          :do-not-import-option="doNotImportOption"
                          :is-field-mapped="isFieldMapped"
                          :get-header-name-by-field-from-target-headers="
                          getHeaderNameByFieldFromTargetHeaders"
                          @add-attribute="onAddAttribute(index)"
                        />
                      </div>
                    </div>
                  </div>
                  <vs-divider class="m-0"></vs-divider>
                </div>
              </draggable>
            </div>
          </div>
        </mq-layout>

        <vs-alert
          :active="!mapStepIsValid"
          :title="$t('FixMappingErrorsMsg')"
          color="danger"
          class="my-6 h-auto"
          icon-pack="feather"
          icon="icon-alert-triangle"
          >
          <ul class="w-full">
            <li
              v-for="(error, index) in mapStepErrors"
              :key="index"
            >
              {{ error }}
            </li>
          </ul>
        </vs-alert>

        <div
          class="mapping-options-message-step2 flex justify-center content-center mt-3">
          <div>
            <p v-html="$t('ColumnsToImportCount', {
              count: countImportedHeader
            })">
            </p>
            <p v-html="$t('ColumnsToIgnoreCount', {
              count: countIgnoredHeader
            })"></p>
          </div>
          <vs-button
            v-scroll-to="{ el: '#import-excel-to-json-card-2-title', offset: -100 }"
            class="ml-5"
            color="primary"
            :disabled="fileHeadersAreInsufficient || !mapStepIsValid"
            @click="confirmStep">
            {{ $t('ConfirmButtonText') }}
          </vs-button>
        </div>
      </div>
    </import-excel-to-json-card>

    <vs-popup
      :title="titleModalAttribute"
      :active.sync="activeModalAttributeCreateOrEdit">
      <transition name="zoom-fade">
        <attribute-create-or-edit
          v-if="showCreateOrEditAttributeComponent"
          :entity="$enums.Entity.CONTACT_ATTRIBUTE"
          :operation="$enums.Operation.CREATE"
          :attributes-type-to-exclude="[$enums.Attributes.Type.CATEGORY]"
          @saved="onAttributeSaved"
          @close="activeModalAttributeCreateOrEdit=false">
        </attribute-create-or-edit>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import ImportExcelToJsonCard from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonCard.vue';
import ImportExcelToJsonStep2MapDataSelectAttribute from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonStep2MapDataSelectAttribute.vue';
import AttributeCreateOrEdit from '@/views/modules/attribute/AttributeListCreateOrEdit.vue';

import ImportExcelToJsonStepMixin from '@/views/modules/_components/import-exce-to-json/import-excel-to-json-step.mixin';

export default {
  name: 'ImportExcelToJsonStep2MapData',
  i18n: {
    messages: {
      en: {
        CardTitle: 'Mapping data',
        CardSubTitle: 'Please select attributes that match your data. You can select existing attributes or create new ones.',
        MappingFileHeader: 'File header',
        MappingDataHeader: 'Data',
        MappingEntityHeader: '{entity} attribute',
        AttributeRequiredMsg: 'The {attribute} attribute is required.',
        AttributeRequiredIfMsg: '{attribute} is required if not mapping {otherAttribute}.',
        ColumnsToImportCount: '<strong class="text-primary">{count} columns</strong> to be imported',
        ColumnsToIgnoreCount: '<strong class="text-primary">{count} columns</strong> ignored',
        ConfirmButtonText: 'Confirm mapping',
        SelectCountryOnPhoneErrorMsg: 'Please select a default country code for phone numbers.',
        FixMappingErrorsMsg: 'Fix the mapping errors to continue with the next step of the import process',
        DoNotImportOption: 'Do not import',
      },
    },
  },
  components: {
    AttributeCreateOrEdit,
    ImportExcelToJsonCard,
    SelectAttribute: ImportExcelToJsonStep2MapDataSelectAttribute,
    draggable,
  },
  mixins: [ImportExcelToJsonStepMixin],
  props: {
    targetHeaders: {
      type: Array,
      required: true,
      validator(columns) {
        return columns.every((h) => h !== null
          && typeof h === 'object'
          && 'headerName' in h
          && 'field' in h
          && 'required' in h);
      },
    },
    createAttributes: {
      type: Boolean,
      required: true,
    },
    countImportedHeader: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeModalAttributeCreateOrEdit: false,
      showCreateOrEditAttributeComponent: false,
      targetHeaderLocalIndexToAddAttribute: null,

      targetHeadersLocal: [...this.targetHeaders],
      fileHeadersLocal: [...this.fileHeaders],

      doNotImportOption: {
        headerName: this.$t('DoNotImportOption'),
        field: null,
        required: false,
        requireIfNull: null,
        validation: null,
      },
      titleModalAttribute: this.$t('$Modals.CreateModalTitle', {
        entity: this.$tc('ContactAttribute', 1),
      }),
    };
  },
  computed: {
    fileHeadersWithSampleData() {
      const result = {};

      this.fileHeaders.forEach((filerHeader) => {
        const value = this.mappedFileData.find(
          (data) => !!data[filerHeader]
            && !!data[filerHeader].trim(),
        );

        result[filerHeader] = value[filerHeader]
          ? value[filerHeader].trim()
          : '';
      });

      return result;
    },
    requiredTargetHeaders() {
      return this.targetHeaders.filter(
        (val) => val !== null && val.field !== null && val.required,
      );
    },
    fileHeadersAreInsufficient() {
      return this.fileHeadersLocal.length < this.requiredTargetHeaders.length;
    },
    countIgnoredHeader() {
      let count = 0;

      this.fileHeadersLocal.forEach((h, index) => {
        if (this.targetHeadersLocal[index].field === null) {
          count += 1;
        }
      });

      return count;
    },
    mapStepErrors() {
      const errors = [];
      this.targetHeaders.forEach((header) => {
        if (header.required && !this.isFieldMapped(header.field)) {
          errors.push(this.$t('AttributeRequiredMsg', {
            attribute: header.headerName,
          }));
        }

        if (
          header.requireIfNull
          && !this.isFieldMapped(header.requireIfNull)
          && !this.isFieldMapped(header.field)) {
          errors.push(this.$t('AttributeRequiredIfMsg', {
            attribute: header.headerName,
            otherAttribute: this.getHeaderNameByFieldFromTargetHeaders(header.requireIfNull),
          }));
        }

        if (
          header.validation
          && header.validation === this.$enums.ImportCollections.Validations.PHONE
          && this.isFieldMapped(header.field)
          && !header.payload) {
          errors.push(this.$t('SelectCountryOnPhoneErrorMsg'));
        }
      });

      return errors;
    },
    mapStepIsValid() {
      return this.mapStepErrors.length === 0;
    },
  },
  watch: {
    $mq: {
      handler(val) {
        if (val === 'mobile') {
          this.targetHeadersLocal = this.targetHeadersLocal.map((header, index) => (
            index < this.fileHeadersLocal.length
              ? header
              : { ...this.doNotImportOption }
          ));
        }
      },
      immediate: true,
    },
    confirmedPreviousStep(val) {
      if (val) {
        if (this.$refs && this.$refs.card && this.$refs.card.isContentCollapsed()) {
          this.$refs.card.toggleContent();
        }
      }
    },
    activeModalAttributeCreateOrEdit(val) {
      if (!val) {
        setTimeout(() => {
          this.showCreateOrEditAttributeComponent = false;
        }, 500);
      } else {
        this.showCreateOrEditAttributeComponent = true;
      }
    },
    fileHeaders(newVal, oldVal) {
      this.fileHeadersLocal = this.fileHeaders;

      if (newVal.length > 0 && oldVal.length === 0) {
        this.sortHeaders();
      }

      this.fileHeadersChanged();
    },
    targetHeadersLocal() {
      this.targetHeaderChanged();
    },
  },
  created() {
    this.sortTargetHeadersLocalByRequired();
  },
  methods: {
    sortTargetHeadersLocalByRequired() {
      this.targetHeadersLocal = this.targetHeadersLocal.sort((a, b) => {
        if (a.required && !b.required) {
          return -1;
        }

        if (!a.required && b.required) {
          return 1;
        }

        return 0;
      });
    },
    getMatchHeaders() {
      let matchHeaders = [];

      this.targetHeadersLocal.forEach((targetHeader, targetHeaderIndex) => {
        this.fileHeadersLocal.forEach((filerHeader, fileHeaderIndex) => {
          const matchTemp = {
            filerHeader: {
              headerName: filerHeader,
              index: fileHeaderIndex,
            },
            targetHeader: {
              targetHeaderObj: targetHeader,
              index: targetHeaderIndex,
            },
            matchScore: 0,
          };

          matchTemp.matchScore += this.areMatchTargetHeaderWithFileHeaderByValue(
            targetHeader,
            filerHeader,
          ) ? 1 : 0;

          matchTemp.matchScore += this.areMatchTargetHeaderWithFileHeaderByHeaderName(
            targetHeader,
            filerHeader,
          ) ? 1 : 0;

          if (matchHeaders.length === 0) {
            matchHeaders.push(matchTemp);
          } else {
            const matchFileHeader = matchHeaders.find(
              (mh) => mh.filerHeader.index === fileHeaderIndex,
            );
            const matchTargetHeader = matchHeaders.find(
              (mh) => mh.targetHeader.index === targetHeaderIndex,
            );

            if (matchFileHeader && matchTargetHeader) {
              if (!matchFileHeader.targetHeader && !matchTargetHeader.filerHeader) {
                matchHeaders = matchHeaders.filter(
                  (mh) => !(mh.filerHeader.index === fileHeaderIndex
                    || mh.targetHeader.index === targetHeaderIndex),
                );

                matchHeaders.push(matchTemp);
              } else if (matchFileHeader.targetHeader && matchTargetHeader.filerHeader) {
                if (matchTemp.matchScore > matchFileHeader.matchScore
                  && matchTemp.matchScore > matchTargetHeader.matchScore) {
                  matchFileHeader.targetHeader = undefined;
                  matchTargetHeader.filerHeader = undefined;
                  matchHeaders.push(matchTemp);
                }
              } else {
                if (!matchFileHeader.targetHeader
                  && matchTemp.matchScore > matchTargetHeader.matchScore) {
                  matchHeaders = matchHeaders.filter(
                    (mh) => !(mh.filerHeader.index === fileHeaderIndex),
                  );

                  matchTargetHeader.filerHeader = matchTemp.filerHeader;
                  matchTargetHeader.matchScore = matchTemp.matchScore;
                }

                if (!matchTargetHeader.filerHeader
                  && matchTemp.matchScore > matchFileHeader.matchScore) {
                  matchHeaders = matchHeaders.filter(
                    (mh) => !(mh.targetHeader.index === targetHeaderIndex),
                  );

                  matchFileHeader.targetHeader = matchTemp.targetHeader;
                  matchFileHeader.matchScore = matchTemp.matchScore;
                }
              }
            } else if (matchFileHeader || matchTargetHeader) {
              if (matchFileHeader) {
                if (!matchFileHeader.targetHeader) {
                  matchHeaders = matchHeaders.filter(
                    (mh) => !(mh.filerHeader.index === fileHeaderIndex),
                  );

                  matchHeaders.push(matchTemp);
                } else if (matchTemp.matchScore > matchFileHeader.matchScore) {
                  matchFileHeader.targetHeader = matchTemp.targetHeader;
                  matchFileHeader.matchScore = matchTemp.matchScore;
                }
              }

              if (matchTargetHeader) {
                if (!matchTargetHeader.filerHeader) {
                  matchHeaders = matchHeaders.filter(
                    (mh) => !(mh.targetHeader.index === targetHeaderIndex),
                  );

                  matchHeaders.push(matchTemp);
                } else if (matchTemp.matchScore > matchTargetHeader.matchScore) {
                  matchTargetHeader.filerHeader = matchTemp.filerHeader;
                  matchTargetHeader.matchScore = matchTemp.matchScore;
                }
              }
            } else {
              matchHeaders.push(matchTemp);
            }
          }
        });
      });

      return matchHeaders.filter((header) => !!header.filerHeader && !!header.targetHeader);
    },
    sortHeaders() {
      const matchHeaders = this.getMatchHeaders();
      const newFileHeaders = [];
      const newTargetHeaders = [];

      matchHeaders.forEach((matchHeader) => {
        newFileHeaders.push(this.fileHeadersLocal[matchHeader.filerHeader.index]);
        newTargetHeaders.push(this.targetHeadersLocal[matchHeader.targetHeader.index]);
      });

      this.fileHeadersLocal = [
        ...newFileHeaders,
        ...this.fileHeadersLocal.filter((fileHeader) => !newFileHeaders.includes(fileHeader)),
      ];
      this.targetHeadersLocal = [
        ...newTargetHeaders,
        ...this.targetHeadersLocal.filter(
          (targetHeader) => !newTargetHeaders.find((th) => th.field === targetHeader.field),
        ),
      ];

      this.$emit('update:file-headers', this.fileHeadersLocal);
    },
    areMatchTargetHeaderWithFileHeaderByValue(targetHeader, filerHeader) {
      if (targetHeader.validateValueOnImport) {
        return targetHeader.validateValueOnImport(this.fileHeadersWithSampleData[filerHeader]);
      }

      return false;
    },
    areMatchTargetHeaderWithFileHeaderByHeaderName(targetHeader, filerHeader) {
      const regex = new RegExp(
        `(${targetHeader.field})|(${targetHeader.headerName})`,
        'gi',
      );

      return regex.test(filerHeader);
    },
    targetHeaderChanged() {
      this.$emit('update:target-headers', this.targetHeadersLocal);
    },
    fileHeadersChanged() {
      if (this.fileHeadersLocal.length > this.targetHeadersLocal.length) {
        const offset = this.fileHeadersLocal.length - this.targetHeadersLocal.length;
        for (let i = 0; i < offset; i += 1) {
          this.targetHeadersLocal.push({
            headerName: this.$t('DoNotImportOption'),
            field: null,
            required: false,
          });
        }
      }
    },
    getSliceItemFromHeader(header, count) {
      if (this.mappedFileData.length > 0) {
        const end = count <= this.mappedFileData.length ? count : this.mappedFileData.length;
        return this.mappedFileData.slice(0, end).map((value) => value[header]);
      }

      return [];
    },
    onAddAttribute(targetHeaderLocalIndex) {
      if (typeof targetHeaderLocalIndex === 'number'
        && targetHeaderLocalIndex < this.targetHeadersLocal.length) {
        this.targetHeaderLocalIndexToAddAttribute = targetHeaderLocalIndex;
      }
      this.activeModalAttributeCreateOrEdit = true;
    },
    onAttributeSaved(attr) {
      if (!attr) return;
      this.activeModalAttributeCreateOrEdit = false;
      this.$emit('attribute-created', attr);
      this.targetHeadersLocal.push({ ...this.doNotImportOption });

      if (typeof this.targetHeaderLocalIndexToAddAttribute === 'number'
        && this.targetHeaderLocalIndexToAddAttribute < this.targetHeadersLocal.length) {
        this.targetHeadersLocal[this.targetHeaderLocalIndexToAddAttribute] = {
          headerName: attr.name,
          field: attr.id,
        };
      }

      this.targetHeaderLocalIndexToAddAttribute = null;
    },
    isFieldMapped(field) {
      return this.targetHeadersLocal.some(
        (h, index) => h.field === field && index < this.fileHeadersLocal.length,
      );
    },
    getHeaderNameByFieldFromTargetHeaders(field) {
      const header = this.targetHeaders.find((h) => h.field === field);
      return header ? header.headerName : null;
    },
    confirmStep() {
      this.$refs.card.toggleContent();
      this.$emit('confirmed');
    },
  },
};
</script>

<style lang="scss" scoped>
.list-draggable-item-import {
  height: 8rem;
  overflow-y: auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .import-mobile-step2-row-main {
    width: 100%;
    display: flex;
  }

  .import-mobile-step2-row-main .import-mobile-step2-title {
    width: 95px;
    font-weight: bold;
    text-align: right;
    margin-right: 20px;
  }

  .mobile-step2-mapping-entity {
    flex-wrap: wrap;
  }

  .mobile-step2-mapping-entity .import-mobile-step2-contents {
    width: 100%;
    padding-right: 1rem !important;
  }

}

@media only screen and (max-width: 550px) {
  .mapping-options-message-step2 {
    display: block !important;
    text-align: center;
    div {
      width: 100%;
      padding-bottom: 1rem;

      p:nth-child(2) strong {
        color: rgba(var(--vs-danger), 1) !important;
      }
    }
  }
}
</style>
