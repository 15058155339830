<template>
  <div class="w-full flex justify-start items-center">
    <p>
      {{ $t('FiltersMatchPrefixLabel') }}
    </p>
    <v-select
      v-model="valueLocal"
      :disabled="disabled"
      class="w-auto mx-4"
      style="min-width: 80px"
      :options="filtersMatchOptions.map((s) => s.value)"
      :get-option-label="(option) =>
          this.filtersMatchOptions.find((s) => s.value === option).label"
      :clearable="false"
      :multiple="false"
      close-on-select>
    </v-select>
    <p>
      {{ $t('FiltersMatchSuffixLabel') }}
    </p>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import enums from '@/enums';

/**
 * filter match select for segment list create or edit
 *
 * @module views/modules/segment/SegmentListCreateOrEditFiltersMatch
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} value - filters match prop value
 * @vue-prop {boolean} disabled - indicate if select must be disabled
 * @vue-data {string} valueLocal -  filters match local value
 * @vue-data {Object[]} filtersMatchOptions - options to filter match
 */
export default {
  name: 'SegmentListCreateOrEditFiltersMatch',
  i18n: {
    messages: {
      en: {
        FiltersMatchPrefixLabel: 'Contacts must match with',
        FiltersMatchSuffixLabel: 'filters',
      },
    },
  },
  components: {
    vSelect,
  },
  props: {
    value: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(enums.AppFilter.FilterMathType).includes(value);
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valueLocal: this.value,
      filtersMatchOptions: Object.values(
        this.$enums.AppFilter.FilterMathType,
      ).map((type) => ({
        label: this.$t(`$AppFilters.$FilterMatch.${type}`),
        value: type,
      })),
    };
  },
  watch: {
    value(val) {
      this.valueLocal = val;
    },
    valueLocal(val) {
      this.$emit('input', val);
    },
  },
};
</script>
