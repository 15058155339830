<template>
  <import-excel-to-json-card
    ref="card"
    :collapse-action="collapseAction"
    :collapsed="true"
    :class="{'opacity-50': !confirmedPreviousStep}"
    card-title-id="import-excel-to-json-card-3-title"
    :icon="confirmed ? 'check_box' : 'looks_3'"
    :color="confirmed ? 'success' : ''"
    :title="$t('CardTitle')"
    :subtitle="$t('CardSubTitle')">
    <div>
      <vs-table
        stripe
        pagination
        :max-items="10"
        search
        :data="mappedImportData">
        <template slot="header">
          <div class="w-full md:w-auto">
            <h5>
              {{ $t('PreviewTitle') }}
            </h5>
            <p v-html="$t('PreviewSubTitle', {
                columns: countImportedHeader,
                rows: mappedImportData.length,
              })">
            </p>
          </div>
        </template>

        <template slot="thead">
          <vs-th
            v-for="header in mappedImportHeaders"
            :key="header.field"
            :sort-key="header.field" >
            {{ header.headerName }}
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr">
            <vs-td
              v-for="(col, indexcol) in data[indextr]"
              :key="indexcol + col"
              :data="col" >
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <div
        v-if="defaultAttributes.length > 0"
        class="my-6">
        <div>
          <h5>
            {{ $t('DefaultAttributesTitle') }}
          </h5>
          <p v-html="$t('DefaultAttributesSubTitle', {
                attributes: this.defaultAttributes.map(
                  (attr) => attr.headerName
                  ).toString().replace(/,/g, ', '),
                })">
          </p>
        </div>

        <div class="mt-5">
          <slot name="default-attributes"></slot>
        </div>
      </div>

      <div>
        <slot name="additional-options"></slot>
      </div>

      <div
        class="flex justify-center">
        <vs-button
          :disabled="!importConfirmIsValid"
          class="ml-4 mt-2"
          color="primary"
          @click="confirmStep">
          {{ $t('ConfirmButtonText') }}
        </vs-button>
      </div>
    </div>
  </import-excel-to-json-card>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';

import ImportExcelToJsonCard from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonCard.vue';
import ImportExcelToJsonStepMixin from '@/views/modules/_components/import-exce-to-json/import-excel-to-json-step.mixin';

export default {
  name: 'ImportExcelToJsonStep3ConfirmImport',
  i18n: {
    messages: {
      en: {
        CardTitle: 'Finalise your import',
        CardSubTitle: 'Preview your data import and confirm.',
        PreviewTitle: 'Preview of your data import',
        PreviewSubTitle: '<strong class="text-primary"> {columns} columns </strong> to be imported with <strong class="text-primary">{rows} rows</strong>',
        DefaultAttributesTitle: 'Select the default attributes to set on all items to import',
        DefaultAttributesSubTitle: 'You can select the next attributes: <strong class="text-primary">{attributes}</strong>',
        ConfirmButtonText: 'Confirm your import',

        ImportedNotifyTitle: '{entity} imported',
        ImportedNotifyMsg: '{entity} imported successfully',
      },
    },
  },
  components: { ImportExcelToJsonCard },
  mixins: [ImportExcelToJsonStepMixin],
  props: {
    defaultAttributes: {
      type: Array,
      required: true,
    },
    onSaveImport: {
      type: Function,
      required: true,
    },
    countImportedHeader: {
      type: Number,
      required: true,
    },
    importConfirmIsValid: {
      type: Boolean,
      required: false,
      default: true,
    },
    targetHeaders: {
      type: Array,
      required: true,
      validator(columns) {
        return columns.every((h) => h !== null
          && typeof h === 'object'
          && 'headerName' in h
          && 'field' in h
          && 'required' in h);
      },
    },
  },
  computed: {
    mappedImportHeaders() {
      return this.targetHeaders.filter(
        (h, index) => h.field !== null && index < this.fileHeaders.length,
      );
    },
    mappedImportData() {
      const headers = this.mappedImportHeaders.map((h) => {
        const targetIndex = this.targetHeaders.findIndex((th) => th.field === h.field);
        const fileHeader = this.fileHeaders[targetIndex];
        return {
          field: h.field,
          fileHeader,
          validation: h.validation || null,
          payload: h.payload || null,
        };
      });

      return this.mappedFileData.map((item) => {
        const itemToReturn = {};
        headers.forEach((header) => {
          if (header.validation
            && header.validation === this.$enums.ImportCollections.Validations.PHONE
            && header.payload && header.payload.iso2 && item[header.fileHeader]) {
            try {
              const phoneNumber = parsePhoneNumber(
                item[header.fileHeader].toString(), header.payload.iso2,
              );

              if (phoneNumber) {
                itemToReturn[header.field] = phoneNumber.formatInternational();
              } else {
                itemToReturn[header.field] = item[header.fileHeader];
              }
            } catch (e) {
              itemToReturn[header.field] = item[header.fileHeader];
            }
          } else {
            itemToReturn[header.field] = item[header.fileHeader];
          }
        });

        return itemToReturn;
      });
    },
    mappedImportDataWithDefaultAttributes() {
      return this.mappedImportData.map((item) => {
        const data = { ...item };

        this.defaultAttributes.forEach((attr) => {
          data[attr.field] = attr.value;
        });

        return data;
      });
    },
    mappedImportDataWithImportIndex() {
      return this.mappedImportDataWithDefaultAttributes.map((item, index) => ({
        ...item,
        importIndex: index,
      }));
    },
  },
  watch: {
    confirmedPreviousStep(val) {
      if (val) {
        if (this.$refs.card.isContentCollapsed()) {
          this.$refs.card.toggleContent();
        }
      }
    },
    mappedImportDataWithImportIndex(val) {
      this.$emit('to-import-count', val.length);
    },
    mappedImportHeaders(val) {
      this.$emit('mapped-import-headers', val);
    },
    mappedImportData(val) {
      this.$emit('mapped-import-data', val);
    },
  },
  methods: {
    async confirmStep() {
      this.$vs.loading({ type: 'radius' });
      const importResult = await this.onSaveImport(this.mappedImportDataWithImportIndex);
      this.$vs.loading.close();

      this.$showSuccessActionNotification({
        title: this.$t('ImportedNotifyTitle', {
          entity: this.$tc(`$Entities.${this.entity}`),
        }),
        text: this.$t('ImportedNotifyMsg', {
          entity: this.$tc(`$Entities.${this.entity}`),
        }),
      });

      this.$refs.card.toggleContent();
      this.$emit('confirmed', importResult);
    },
  },
};
</script>

<style lang="scss">
</style>
