<template>
  <vs-chip
    v-if="params.value"
    class="ag-grid-cell-chip"
    :color="chipColor">
    <feather-icon
      :icon="chipIcon"
      svgClasses="h-5 w-5 mr-1" />
    <span>
      {{ chipText }}
    </span>
  </vs-chip>
</template>

<script>

/**
 * Cell renderer to show contact marketing status
 *
 * @module views/modules/contacts/contact/ContactListCellRendererMarketingStatus
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed  {string} chipColor - color of chip
 * @vue-computed  {string} chipText - text of chip
 */
export default {
  name: 'ContactListCellRendererMarketingStatus',
  computed: {
    chipColor() {
      if (this.params && this.params.value) {
        switch (this.params.value.value) {
          case this.$enums.Contact.MarketingStatus.SUBSCRIBED:
            return 'success';

          case this.$enums.Contact.MarketingStatus.UNSUBSCRIBED:
            return 'danger';

          default:
            return '';
        }
      }

      return '';
    },
    chipText() {
      if (this.params && this.params.value) {
        return this.$t(`$Enums.Contact.MarketingStatus.${this.params.value.value}`);
      }

      return '';
    },
    chipIcon() {
      if (this.params && this.params.value) {
        switch (this.params.value.value) {
          case this.$enums.Contact.MarketingStatus.SUBSCRIBED:
            return 'CheckCircleIcon';

          case this.$enums.Contact.MarketingStatus.UNSUBSCRIBED:
            return 'XCircleIcon';

          default:
            return '';
        }
      }

      return '';
    },
  },
};
</script>
