import commonListCreateOrEditWithAgGrid
from '@/views/modules/_mixins/commonListCreateOrEditWithAgGrid';
import commonTrashList from '@/views/modules/_mixins/commonTrashList';

/**
 * mixins for template list with trash and ag grid
 *
 * @module src/views/modules/templates/mixins/commonTemplateListCreateOrEdit.js
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} cloneDisabled - indicate if clone operation if disabled
 * @vue-data {boolean} editDisabled - indicate if edit operation if disabled
 * @vue-data {Function} actionFromHeaderSelectionFunction -
 * function to call on action from header selection
 * @vue-data {Function | null} afterRestoreRecordFunction - function to call after restore one item
 * @vue-data {Function | null} afterRestoreRecordsFunction -
 * function to call after restore many items
 * @vue-computed {Object[]} [additionalActionsOnList=[]] - additional actions on list
 * @vue-computed {Object[]} additionalActionsOnListForTrash -
 * additional list actions for trash list
 * @vue-computed {Object[]} allMultipleActionOptions -
 * all multiple action options with trash options
 * @vue-event {void} confirmRestoreRecordsForTrashWithAgGrid -
 * called to confirm restore for one record
 * @vue-event {void} actionFromHeaderSelectionForTrash -
 * called on action to make it from header selection in ag grid
 * @vue-event {void} onActionFromCreateOrEditForTrash -
 * called on action to make it from create or edit
 * @vue-event {void} onRestoreFromCreateOrEdit -
 * called form create or edit to restore one template
 */
export default {
  mixins: [commonListCreateOrEditWithAgGrid, commonTrashList],
  data() {
    return {
      cloneDisabled: this.trash,
      editDisabled: this.trash,
      actionFromHeaderSelectionFunction: this.actionFromHeaderSelectionForTrash,
      afterRestoreRecordFunction: this.resetDataSourceAndSelection,
      afterRestoreRecordsFunction: this.resetDataSourceAndSelection,
    };
  },
  computed: {
    additionalActionsOnList() {
      return this.additionalActionsOnListForTrash;
    },
    additionalActionsOnListForTrash() {
      return [
        {
          name: 'restore',
          color: 'warning',
          text: '$General.Restore',
          position: 'top',
          icon: 'icon-rotate-ccw',
          iconVue: 'RotateCcwIcon',
          activeCreateOrEdit: this.operation === this.$enums.Operation.VIEW,
          active: this.trash,
        },
      ];
    },
    allMultipleActionOptions() {
      return [
        ...this.trashMultipleActionOptions,
        ...this.defaultMultipleActionOptions,
      ];
    },
  },
  methods: {
    confirmRestoreRecordsForTrashWithAgGrid() {
      this.confirmRestoreRecords({
        isAnyRowsSelected: this.isAnyRowsSelected,
        rowsSelectedCount: this.rowsSelectedCountWithAllSelection,
        rowsSelected: this.rowsSelectedWithAllSelectionOrNull,
        filters: this.getMappedFilterModel(),
      });
    },
    actionFromHeaderSelectionForTrash(action = '') {
      switch (action) {
        case 'restore':
          this.confirmRestoreRecordsForTrashWithAgGrid();
          break;

        default:
          this.actionFromHeaderSelection(action);
      }
    },
    onActionFromCreateOrEditForTrash(action) {
      switch (action) {
        case 'restore':
          this.onRestoreFromCreateOrEdit(this.recordSelected);
          break;

        default:
          this.onActionFromCreateOrEdit(action);
      }
    },
    async onRestoreFromCreateOrEdit(payload) {
      this.dontConfirmCloseCreateOrEdit = true;
      this.activeModalCreateOrEdit = false;

      const confirmed = await this.confirmRestoreRecord(payload);

      if (confirmed) {
        this.showCreateOrEditComponent = false;
      } else {
        this.activeModalCreateOrEdit = true;
      }

      this.dontConfirmCloseCreateOrEdit = false;
    },
  },
};
