import BaseConstructor from '@/views/modules/_common/base.constructor';
import enums from '@/enums';

/**
 * Create a new contact attribute
 * @class AttributeConstructor
 */
export default class AttributeConstructor extends BaseConstructor {
  name = '';

  type = 'text';

  items = [];

  constructor(operation = enums.Operation.CREATE, payload = null) {
    super(operation, payload);

    this.name = operation === enums.Operation.CREATE || !payload ? '' : payload.name || '';
    this.type = operation === enums.Operation.CREATE || !payload ? '' : payload.type || '';
    this.items = operation === enums.Operation.CREATE || !payload ? [] : payload.items || [];
  }

  toCreatePayload() {
    return {
      name: this.name,
      type: this.type,
      items: this.items,
    };
  }

  toEditPayload() {
    return {
      id: this.payload.id,
      ...this.toCreatePayload(),
      // eslint-disable-next-line no-underscore-dangle
      items: this.items.map((i) => ({ _id: i._id, name: i.name })),
    };
  }
}
