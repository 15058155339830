export default {
  props: {
    entity: {
      type: String,
      required: true,
    },
    collapseAction: {
      type: Boolean,
      required: true,
    },
    confirmed: {
      type: Boolean,
      required: true,
    },
    confirmedPreviousStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    fileHeaders: {
      type: Array,
      required: true,
    },
    mappedFileData: {
      type: Array,
      required: true,
    },
  },
};
