var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"excel-import"},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(`ext:${_vm.acceptExtListVeeValidate}|size:${_vm.maxFileSize}`),expression:"`ext:${acceptExtListVeeValidate}|size:${maxFileSize}`"}],ref:"fileInput",staticClass:"hidden",attrs:{"type":"file","name":_vm.fileInputName,"accept":_vm.acceptExtList,"data-vv-validate-on":"blur|input|change","danger":_vm.errors.has(_vm.fileInputName),"danger-text":_vm.errors.first(_vm.fileInputName)},on:{"change":_vm.onInputFileChange}}),_c('div',{class:[
        'px-8',
        !_vm.minimal ? 'py-8' : 'py-2',
        'text-center', 'border-dashed', 'text-xl', 'border-2',
        _vm.borderDanger || _vm.errors.has(_vm.fileInputName) ? 'border-danger' : 'd-theme-border-grey-light',
        'd-theme-dark-bg',
        this.file === null ? 'cursor-pointer' : '',
        this.dragIn ? 'bg-drag-color' : ''
        ],on:{"click":_vm.onClickDragContainer,"drop":_vm.onDropContainer,"dragover":_vm.onDragoverContainer,"dragenter":_vm.onDragoverContainer,"dragleave":_vm.onDragLeaveContainer}},[(_vm.file === null)?[(!_vm.minimal)?_c('feather-icon',{staticClass:"block",attrs:{"icon":"UploadCloudIcon","svgClasses":"h-16 w-16 stroke-current text-grey"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t('ContainerDropMsg', { type: this.fileType }))+" ")]),_c('a',{staticClass:"font-medium text-primary link-cta",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$refs.fileInput.click()}}},[_vm._v(_vm._s(_vm._f("lowercase")(_vm.$t('$General.Browse'))))])]:[(!_vm.minimal)?_c('feather-icon',{staticClass:"block",attrs:{"icon":"TrashIcon","svgClasses":"h-16 w-16 stroke-current text-grey cursor-pointer"},on:{"click":function($event){$event.preventDefault();return _vm.removeFile.apply(null, arguments)}}}):_vm._e(),_c('p',[_vm._v(_vm._s(this.file.name))])]],2),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has(_vm.fileInputName)),expression:"errors.has(fileInputName)"}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.fileInputName))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }