<template>
  <v-select
    ref="vSelect"
    v-model="valueLocal"
    class="w-full"
    :options="conditionsFromAttributeType"
    :multiple="false"
    :clearable="false"
    close-on-select
    :disabled="disabled"
    :getOptionLabel="(option) => this.$t(`$AppFilters.$Operation.${option}`)"
  />
</template>

<script>
import vSelect from 'vue-select';
import enums from '@/enums';

// Mixins
import filterMixin from '@/views/modules/segment/create-or-edit/_mixins/filter.mixin';

/**
 * conditions options for input based filters
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditInputConditionsSelect
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} value - value
 * @vue-prop {string} filterType - type on filter
 * @vue-data {string[]} textConditionOptions - text conditions options
 * @vue-data {string[]} numberConditionOptions - number conditions options
 * @vue-data {string[]} dateConditionOptions - date conditions options
 * @vue-event {string[]} conditionsFromAttributeType -
 * return the conditions for filter by type of the attribute
 */
export default {
  name: 'SegmentListCreateOrEditInputConditionsSelect',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
      validator(type) {
        return Object.values(enums.AppFilterType).includes(type);
      },
    },
  },
  mixins: [filterMixin],
  data() {
    return {
      textConditionOptions: Object.values(this.$enums.AppFilter.FilterType.Text.Type),
      numberConditionOptions: Object.values(this.$enums.AppFilter.FilterType.Number.Type),
      dateConditionOptions: Object.values(this.$enums.AppFilter.FilterType.Date.Type),
    };
  },
  computed: {
    conditionsFromAttributeType() {
      switch (this.filterType) {
        case this.$enums.AppFilterType.TEXT:
          return this.textConditionOptions;
        case this.$enums.AppFilterType.NUMBER:
          return this.numberConditionOptions;
        case this.$enums.AppFilterType.DATE:
          return this.dateConditionOptions;

        default:
          return [];
      }
    },
  },
};
</script>

<style scoped>

</style>
