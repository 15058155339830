<template>
  <form @submit.prevent>
    <div
      class="vx-row mb-6" >
      <div class="vx-col w-full">
        <label
          class="vs-input--label"
          :class="{required: !isView && !isEdition}">
          {{ $t('$AttributeModule.AttributeType') }}
        </label>
        <v-select
          v-model="model.type"
          class="w-full"
          :class="{'border-danger': errors.has($t('$AttributeModule.AttributeType'))}"
          :disabled="isView || isEdition"
          :options="attributeTypeOptionsFiltered.map((s) => s.value)"
          :clearable="false"
          :multiple="false"
          close-on-select
          :getOptionLabel="(option) =>
          attributeTypeOptionsFiltered.find((s) => s.value === option).text"
          @option:selected="validateAttributeType()"
          @search:blur="validateAttributeType()"
        />
        <vs-input
          class="w-full"
          :value="model.type"
          type="hidden"
          :name="$t('$AttributeModule.AttributeType')"
          v-validate="'required'"
          data-vv-validate-on="blur|input"
          :danger="errors.has($t('$AttributeModule.AttributeType'))"
          :danger-text="errors.first($t('$AttributeModule.AttributeType'))"/>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          v-model="model.name"
          class="w-full"
          :class="{required: !isView}"
          :disabled="isView"
          :name="$t('$AttributeModule.AttributeName')"
          :label="$t('$AttributeModule.AttributeName')"
          type="text"
          v-validate="'required|max:100'"
          data-vv-validate-on="blur|input"
          :danger="errors.has($t('$AttributeModule.AttributeName'))"
          :danger-text="errors.first($t('$AttributeModule.AttributeName'))"
          val-icon-danger="clear"/>
      </div>

      <div
        v-if="model.type === $enums.Attributes.Type.CATEGORY"
        class="vx-col w-full categories-name mt-5">
        <p class="my-3 text-sm">{{ $tc('$AttributeModule.CategoryName', 2) }}</p>
        <draggable
          :list="model.items"
          class="p-2">
          <div
            v-for="(item, index) in model.items"
            :key="index"
            :class="['vx-row', index > 0 ? 'mt-3' : '']">
            <div
              v-if="isCreation || isEdition"
              class="vx-col w-2/12 md:w-1/12 pr-0 flex justify-center items-center">
              <feather-icon
                icon="MoreVerticalIcon"
                svgClasses="h-7 w-7 hover:text-primary cursor-pointer"/>
            </div>
            <div class="vx-col w-8/12 md:w-5/12 px-0">
              <vs-input
                v-model="item.name"
                :disabled="isView"
                class="w-full"
                :placeholder="$tc('$AttributeModule.CategoryName', 1)"
                type="text"
                :name="`${$tc('$AttributeModule.CategoryName', 1)} ${index + 1}`"
                v-validate="'required|max:100'"
                data-vv-validate-on="blur|input"
                :danger="errors.has(`${$tc('$AttributeModule.CategoryName', 1)} ${index + 1}`)"
                :danger-text="errors.first(`${$tc('$AttributeModule.CategoryName', 1)} ${index + 1}`)"
                val-icon-danger="clear"/>
            </div>
            <div
              v-if="isCreation || isEdition"
              class="vx-col w-2/12 md:w-1/12 pl-0 flex justify-center items-center">
              <feather-icon
                icon="MinusCircleIcon"
                svgClasses="h-7 w-7 hover:text-danger cursor-pointer"
                @click.prevent="deleteCategoryItem(index)"/>
            </div>
          </div>
        </draggable>
        <vs-button
          v-if="isCreation || isEdition"
          class="mt-3"
          icon-pack="feather"
          icon="icon-plus"
          size="small"
          color="primary"
          type="border"
          @click="addCategoryItem">
          {{ $t('$General.Add') }}
        </vs-button>
      </div>
    </div>

    <base-form-footer-action-buttons
      :hide-save="isView"
      :actions="mappedActions"
      @action="onAction"
      @save="save(model.toSavePayload())"
      @cancel="$emit('close')">
      <template
        v-if="isView"
        v-slot:cancel>
        {{ $t("$General.Close") }}
      </template>
    </base-form-footer-action-buttons>
  </form>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import enums from '@/enums';

// Mixins
import singleCreateOrEdit from '@/views/modules/_mixins/singleCreateOrEdit';

// Constructor
import AttributeConstructor from '@/views/modules/attribute/attribute.constructor';
import NameConstructor from '@/views/modules/_common/name.constructor';

// Custom Components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

/**
 * Component to create or edit contact attributes
 *
 * @module views/modules/audience/attribute/AttributeCreateOrEdit
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string[]} attributesTypeToExclude - attributes to exclude
 * @vue-data {Object} [model={...}] - attribute model to save
 * @vue-data {Array.<Object>} attributeTypeOptions - types of attributes to add
 * @vue-data {Function | null} [addItemFunction=null] - function to add one attribute
 * @vue-data {Function | null} [editItemFunction=null] - function to edit one attribute
 * @vue-computed  {boolean} verifyProperties - verify is form to save is valid
 * @vue-computed  {Object[]} attributeTypeOptionsFiltered -
 * filtered attribute type without exclude types
 * @vue-event {void} deleteCategoryItem - remove a category item of category attribute
 * @vue-event {void} addCategoryItem - add a category item on category attribute
 * @vue-event {void} validateAttributeType - validate the attribute type field
 */
export default {
  name: 'AttributeCreateOrEdit',
  components: {
    draggable,
    BaseFormFooterActionButtons,
    vSelect,
  },
  mixins: [singleCreateOrEdit],
  props: {
    attributesTypeToExclude: {
      type: Array,
      required: false,
      default() {
        return [];
      },
      validator(types) {
        return types.every(
          (t) => [
            enums.Attributes.Type.TEXT,
            enums.Attributes.Type.DATE,
            enums.Attributes.Type.NUMBER,
            enums.Attributes.Type.BOOL,
            enums.Attributes.Type.CATEGORY,
          ].indexOf(t) !== -1,
        );
      },
    },
  },
  data() {
    return {
      model: new AttributeConstructor(this.operation, this.modelPayload),
      attributeTypeOptions: [
        {
          text: this.$t('$General.Text'),
          value: this.$enums.Attributes.Type.TEXT,
        },
        {
          text: this.$t('$General.Date'),
          value: this.$enums.Attributes.Type.DATE,
        },
        {
          text: this.$t('$General.Number'),
          value: this.$enums.Attributes.Type.NUMBER,
        },
        {
          text: this.$t('$General.Boolean'),
          value: this.$enums.Attributes.Type.BOOL,
        },
        {
          text: this.$tc('$General.Category'),
          value: this.$enums.Attributes.Type.CATEGORY,
        },
      ],
      addItemFunction: this.addAttribute,
      editItemFunction: this.editAttribute,
    };
  },
  computed: {
    verifyProperties() {
      return this.model.name !== '' && this.model.attributeName !== ''
        && this.model.type !== '' && (this.model.type !== this.$enums.Attributes.Type.CATEGORY || this.model.items.length > 0);
    },
    attributeTypeOptionsFiltered() {
      return this.attributeTypeOptions.filter(
        (attrType) => this.attributesTypeToExclude.indexOf(attrType.value) === -1,
      );
    },
  },
  watch: {
    'model.type': function () {
      if (this.operation === 'create') {
        if (this.model.type === this.$enums.Attributes.Type.CATEGORY) {
          this.model.items = [
            new NameConstructor(),
            new NameConstructor(),
            new NameConstructor(),
          ];
        } else {
          this.model.items = [];
        }
      } else if (this.model.type === this.$enums.Attributes.Type.CATEGORY
        && this.model.items.length === 0) {
        this.model.items = [
          new NameConstructor(),
          new NameConstructor(),
          new NameConstructor(),
        ];
      }
    },
  },
  methods: {
    ...mapActions({
      addAttribute: 'attribute/addAttribute',
      editAttribute: 'attribute/editAttribute',
    }),
    deleteCategoryItem(index) {
      this.model.items.splice(index, 1);
    },
    addCategoryItem() {
      this.model.items.push(new NameConstructor());
    },
    validateAttributeType() {
      this.$nextTick(() => {
        this.$validator.validate(this.$t('$AttributeModule.AttributeType'));
      });
    },
  },
};

</script>

<style scoped>
  .categories-name {
    padding-left: 2.5rem !important;
  }
</style>
