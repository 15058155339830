<template>
<div>
  <v-select
    :value="value"
    class="w-full"
    :class="{'border-danger': ifRequiredIfErrorAfterFileHeaders(index, value)}"
    :disabled="enableDisabled && (ifRequiredIfError(index, value) || value.required)"
    label="headerName"
    :multiple="false"
    :closeOnSelect="true"
    :clearable="false"
    :options="optionsToSelection"
    :selectable="isSelectableOption"
    @input="$emit('input', $event)">
    <template #selected-option="{ headerName, field }">
      <div
        v-if="field === null"
        class="text-center text-danger">
        <strong>{{ headerName }}</strong>
      </div>
      <div v-else>
        {{ headerName }} -
        <small class="text-gray">
          {{ $tc(`$Entities.${entity}`) }}
        </small>
      </div>
    </template>
    <template #option="{ headerName, field }">
      <div
        v-if="field === null"
        class="text-center">
        <strong>{{ headerName }}</strong>
      </div>
      <div v-else>
        {{ headerName }} -
        <small class="text-gray">
          {{ $tc(`$Entities.${entity}`) }}
        </small>
      </div>
    </template>
    <template
      v-if="createAttributes"
      #list-footer>
      <vs-divider class="my-1"/>
      <div class="text-center">
        <a
          href="#"
          class="link-plain"
          @click.prevent="$emit('add-attribute')">
          {{ $t('AddAttributeMsg') }}
        </a>
      </div>
    </template>
  </v-select>

  <div
    v-if="value && value.validation
    && value.validation === $enums.ImportCollections.Validations.PHONE"
    class="mt-2">
    <v-select
      v-model="value.payload"
      class="w-full"
      :class="{'border-danger':  index < fileHeaders.length && !value.payload}"
      label="name"
      :placeholder="$t('SelectCountryOnPhonePlaceholder')"
      :multiple="false"
      :closeOnSelect="true"
      :clearable="false"
      :options="countries">
      <template #selected-option="{ name, dialCode }">
        <div>
          {{ name }} :
          <small class="text-gray">
            +{{ dialCode }}
          </small>
        </div>
      </template>
      <template #option="{ name, dialCode }">
        <div>
          {{ name }} :
          <small class="text-gray">
            +{{ dialCode }}
          </small>
        </div>
      </template>
    </v-select>
  </div>

  <div
    v-if="showSelectCountryOnPhoneError(index, value)"
    class="flex items-center mt-1">
    <vx-tooltip
      :text="$t('SelectCountryOnPhoneHelp')"
      :position="$mq === 'mobile' ? 'right' : 'top'"
      class="inline-block cursor-pointer">
      <feather-icon
        icon="HelpCircleIcon"
        svgClasses="h-4 w-4 text-gray-dark"/>
    </vx-tooltip>

    <small
      class="text-danger ml-2">
      {{
        $t('SelectCountryOnPhoneErrorMsg')
      }}
    </small>
  </div>

  <template v-if="value.required">
    <small
      v-if="index >= fileHeaders.length"
      class="text-danger">
      {{ $t('ThisAttributeRequiredMsg') }}
    </small>
  </template>
  <template v-else>
    <template v-if="value.requireIfNull">
      <small
        v-if="ifRequiredIfError(index, value)
        && !showSelectCountryOnPhoneError(index, value)"
        :class="`text-${ifRequiredIfErrorInFileHeaders(index, value)
        ? 'info'
        : 'danger'}`">
        {{ $t('AttributeRequiredIfMsg', {
        attribute: value.headerName,
        otherAttribute: getHeaderNameByFieldFromTargetHeaders(value.requireIfNull)
      }) }}
      </small>
      <template v-else>
        <small
          v-if="index >= fileHeaders.length && value.field !== null"
          class="text-info">
          {{ $t('IgnoredAttributeMsg') }}
        </small>
      </template>
    </template>
    <template v-else>
      <small
        v-if="index >= fileHeaders.length && value.field !== null"
        class="text-info">
        {{ $t('IgnoredAttributeMsg') }}
      </small>
    </template>
  </template>
</div>
</template>

<script>
import vSelect from 'vue-select';
import countries from '@/assets/utils/all-countries';

export default {
  name: 'ImportExcelToJsonStep2MapDataSelectAttribute',
  i18n: {
    messages: {
      en: {
        AddAttributeMsg: 'Add a new attribute',
        IgnoredAttributeMsg: 'This attribute will be ignored',
        AttributeRequiredIfMsg: '{attribute} is required if not mapping {otherAttribute}.',
        ThisAttributeRequiredMsg: 'This attribute is required.',
        SelectCountryOnPhoneErrorMsg: 'Please select a default country code.',
        SelectCountryOnPhoneHelp: 'The country code you select here will be applied to all phone numbers that do not already have any country code attached. For example: if you choose Australia, any number without a +61 (or any other country code) will automatically apply the Australian country code to the number.',
        SelectCountryOnPhonePlaceholder: 'Select a country code',
      },
    },
  },
  components: {
    vSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    fileHeaders: {
      type: Array,
      required: true,
    },
    targetHeaders: {
      type: Array,
      required: true,
      validator(columns) {
        return columns.every((h) => h !== null
          && typeof h === 'object'
          && 'headerName' in h
          && 'field' in h
          && 'required' in h);
      },
    },
    targetHeadersLocal: {
      type: Array,
      required: true,
    },
    createAttributes: {
      type: Boolean,
      required: true,
    },
    doNotImportOption: {
      type: Object,
      required: true,
    },
    enableDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    getHeaderNameByFieldFromTargetHeaders: {
      type: Function,
      required: true,
    },
    isFieldMapped: {
      type: Function,
      required: true,
    },
  },
  computed: {
    countries() {
      return countries;
    },
    optionsToSelection() {
      const optionalHeaders = this.targetHeaders.filter(
        (val) => val !== null && val.field !== null && (!val.required || this.$mq === 'mobile'),
      );

      return [
        { ...this.doNotImportOption },
        ...optionalHeaders,
      ];
    },
  },
  methods: {
    isSelectableOption(option, index = null, item = null) {
      if (index !== null && item !== null && option.field === null) {
        return !this.ifRequiredIfError(index, item, false);
      }

      return option.field === null
        || !this.targetHeadersLocal.some(
          (h) => h.headerName === option.headerName,
        );
    },
    ifRequiredIfError(index, item) {
      return item.requireIfNull
        && item.field !== null
        && this.getHeaderNameByFieldFromTargetHeaders(item.requireIfNull)
        && !this.isFieldMapped(item.requireIfNull);
    },
    ifRequiredIfErrorInFileHeaders(index, item) {
      return this.ifRequiredIfError(index, item)
        && index < this.fileHeaders.length;
    },
    ifRequiredIfErrorAfterFileHeaders(index, item) {
      return this.ifRequiredIfError(index, item)
        && index >= this.fileHeaders.length;
    },
    showSelectCountryOnPhoneError(index, item) {
      return index < this.fileHeaders.length
        && item.validation === this.$enums.ImportCollections.Validations.PHONE
        && !this.targetHeadersLocal[index].payload;
    },
  },
};
</script>

<style scoped>

</style>
