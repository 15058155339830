<template>
  <import-excel-to-json
    :key="importComponentKey"
    :target-headers="columnDefsToImport"
    :entity="$enums.Entity.CONTACT"
    :on-save-import="onImport"
    :create-attributes="true"
    :default-attributes="defaultAttributesToImportContacts"
    :import-confirm-is-valid="importOptInAgreement"
    @refresh="onRefresh"
    @attribute-created="$emit('attribute-created', $event)"
    @imported="onImported"
    @close="$emit('close')">
    <template v-slot:default-attributes>
      <div
        class="vx-row">
        <div class="vx-col w-full md:w-1/2 mt-3 md:mt-0">
          <label class="vs-input--label">{{ $tc('$General.Tag', 2) }}</label>
          <tags-drop-down-filter
            :ag-grid-floating-filter="false"
            :selected-tags.sync="tagsToImportContacts">
          </tags-drop-down-filter>
        </div>
      </div>

      <div
        v-for="category in categoriesFromSelectedAudience"
        :key="category.id"
        class="mt-3">
        <label class="vs-input--label">{{ category.name }}</label>
        <div class="vx-row mt-1">
          <div
            v-for="item in category.items"
            :key="item.id"
            class="vx-col w-1/2 md:w-1/4 lg:w-1/6 pb-1">
            <div class="vs-component con-vs-checkbox vs-checkbox-primary vs-checkbox-default">
              <input
                v-model="categoriesToImportContacts[category.id]"
                :value="item"
                type="checkbox"
                class="vs-checkbox--input">
              <span
                class="checkbox_x vs-checkbox"
                style="border: 2px solid rgb(180, 180, 180);">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon notranslate
                    icon-scale vs-checkbox--icon material-icons null">
                      check
                    </i>
                  </span>
                </span>
              <span class="con-slot-label text-sm">{{ item.name | truncateEllipsis(18) }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:additional-options>
      <div class="mt-base">
        <h5>
          {{ $t('$ContactModule.$ImportContacts.ContactsManagement') }}
        </h5>

        <ul class="mt-5">
          <li class="flex items-center my-3">
            <vs-switch
              v-model="importBlacklist"
              color="primary"
              class="d-inline-block"
            />
            <label class="d-inline-block ml-3">
              {{ $t('$ContactModule.$ImportContacts.BlacklistImportedContacts') }}
            </label>
            <vx-tooltip
              :text="$t('$ContactModule.$ImportContacts.BlacklistImportedContactsMsg')"
              :position="$mq === 'mobile' ? 'left' : 'top'"
              class="inline-block flex items-center">
              <feather-icon
                class="ml-1"
                icon="InfoIcon"
                svgClasses="h-4 w-4 hover:text-primary cursor-pointer"/>
            </vx-tooltip>
          </li>
        </ul>
      </div>

      <div class="mt-base">
        <h5>
          {{ $t('$ContactModule.$ImportContacts.OptInAgreement') }}
        </h5>

        <contact-opt-in-agreement
          ref="optInAgreement"
          v-model="importOptInAgreement"
          :plural="true"
          :popoverConditions="false"
          class="w-full mt-5"/>
      </div>
    </template>
  </import-excel-to-json>

</template>

<script>
import { mapActions } from 'vuex';

import TagsDropDownFilter from '@/views/modules/contacts/filters/TagsDropDownFilter';
import ContactOptInAgreement from '@/views/modules/contacts/_components/ContactOptInAgreement.vue';
import ImportExcelToJson from '@/views/modules/_components/import-exce-to-json/ImportExcelToJson.vue';

export default {
  name: 'ContactListImporter',
  components: {
    ImportExcelToJson,
    ContactOptInAgreement,
    TagsDropDownFilter,
  },
  props: {
    columnDefsToImport: {
      type: Array,
      required: true,
    },
    allContactAttributes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      importComponentKey: 0,
      tagsToImportContacts: [],
      categoriesToImportContacts: {},
      importOptInAgreement: false,
      importBlacklist: false,
    };
  },
  computed: {
    categoriesFromSelectedAudience() {
      return this.allContactAttributes.filter(
        (attr) => attr.type === this.$enums.Attributes.Type.CATEGORY,
      );
    },
    defaultAttributesToImportContacts() {
      const attributes = [
        {
          headerName: this.$tc('$Entities.Tag', 2),
          field: 'tags',
          value: this.tagsToImportContacts.map((t) => t.id),
        },
      ];

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.categoriesToImportContacts)) {
        const category = this.categoriesFromSelectedAudience.find((c) => c.id === key);

        if (category) {
          attributes.push({
            headerName: category.name,
            field: category.id,
            value,
          });
        }
      }

      return attributes;
    },
  },
  watch: {
    categoriesFromSelectedAudience: {
      handler(categories) {
        categories.forEach((attr) => {
          const value = this.categoriesToImportContacts[attr.id] || undefined;
          this.$set(this.categoriesToImportContacts, attr.id, value !== undefined ? value : []);
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      importContactsFromFile: 'contact/importFromFile',
    }),
    async onImport(contacts) {
      return this.importContactsFromFile({
        payload: contacts,
        blacklist: this.importBlacklist,
      });
    },
    onImported() {
      this.resetDataToImport();
      this.$emit('imported');
    },
    onRefresh() {
      this.resetDataToImport();
      this.importComponentKey += 1;
    },
    resetDataToImport() {
      this.tagsToImportContacts = [];
      this.categoriesToImportContacts = {};
      this.importOptInAgreement = false;
      this.importBlacklist = false;
    },
  },
};
</script>

<style scoped>

</style>
