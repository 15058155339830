<template>
  <div>
    <v-select
      ref="vSelect"
      v-model="valueLocal"
      class="w-full"
      :options="booleansValuesOptions"
      :multiple="false"
      :clearable="false"
      close-on-select
      :getOptionLabel="(option) => this.$t(`$AppFilters.$FilterType.$Boolean.$Values.${option}`)"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';
import enums from '@/enums';

// Mixins
import filterMixin from '@/views/modules/segment/create-or-edit/_mixins/filter.mixin';

/**
 * boolean filter for create or edit segment
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditBooleanFilter
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} value - value
 * @vue-data {string[]} booleansValuesOptions - boolean values for filters
 */
export default {
  name: 'SegmentListCreateOrEditBooleanFilter',
  components: {
    vSelect,
  },
  mixins: [filterMixin],
  props: {
    value: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(enums.AppFilter.FilterType.Boolean.Values).includes(value);
      },
    },
  },
  data() {
    return {
      booleansValuesOptions: Object.values(
        this.$enums.AppFilter.FilterType.Boolean.Values,
      ),
    };
  },
};
</script>

<style scoped>

</style>
