<template>
  <vs-alert
    :active.sync="value"
    class="text-center"
    color="grey-dark"
    closable
    icon-pack="feather"
    icon="icon-info"
    close-icon="icon-x">
    {{ $t('SelectedAllPageInListMsg', {
    count: rowsSelectedCount,
    entity: $tc(`$Entities.${entity}`, rowsSelectedCount) }) }}
    <a
      href="#"
      @click.prevent="$emit('select-all')">
      {{ $t('SelectAllInListMsg', {
      count: count,
      entity: $tc(`$Entities.${entity}`, count) }) }}
    </a>
  </vs-alert>
</template>

<script>

/**
 * Component to show exporting alert messages
 *
 * @module views/modules/SelectionAlertInfo
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'SelectionAlertInfo',
  i18n: {
    messages: {
      en: {
        SelectedAllPageInListMsg: 'All {count} {entity} on this page have been selected.',
        SelectAllInListMsg: 'Select all {count} {entity}.',
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    rowsSelectedCount: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
