import BaseConstructor from '@/views/modules/_common/base.constructor';
import enums from '@/enums';

export default class SegmentConstructor extends BaseConstructor {
  name = '';

  filters = null;

  filtersMatch = enums.AppFilter.FilterMathType.ALL;

  constructor(
    filters,
    filtersMatch,
    operation = enums.Operation.CREATE,
    payload = null,
    prioritizeFilters = false,
  ) {
    super(operation, payload);

    this.name = operation === enums.Operation.CREATE || !payload ? '' : payload.name || '';

    if (prioritizeFilters) {
      this.filters = !payload
        ? filters || null
        : filters || payload.filters || null;
      this.filtersMatch = !payload
        ? filtersMatch || enums.AppFilter.FilterMathType.ALL
        : filtersMatch || payload.filtersMatch || enums.AppFilter.FilterMathType.ALL;
    } else {
      this.filters = operation === enums.Operation.CREATE || !payload
        ? filters || null : payload.filters || null;
      this.filtersMatch = operation === enums.Operation.CREATE || !payload
        ? filtersMatch || enums.AppFilter.FilterMathType.ALL
        : payload.filtersMatch || enums.AppFilter.FilterMathType.ALL;
    }
  }

  toCreatePayload() {
    return {
      name: this.name,
      filters: this.filters,
      filtersMatch: this.filtersMatch,
    };
  }

  toEditPayload() {
    return {
      id: this.payload.id,
      ...this.toCreatePayload(),
    };
  }
}
