<template>
  <div>
    <div class="mt-8 flex flex-wrap items-center justify-between">
      <h2 class="text-content">{{ $t('FileImportTitle') }}</h2>
      <vs-button
        class="ml-4 mt-2 vs-button-cancel-flat"
        color="danger"
        @click="$emit('close')">
        {{ $t('$General.Cancel') }}
      </vs-button>
    </div>

    <vs-divider></vs-divider>

    <import-excel-to-json-step1-upload-file
      :entity="entity"
      :collapse-action="!completed"
      :confirmed="step1"
      :file-headers.sync="fileHeaders"
      :mapped-file-data="mappedFileData"
      @update:mapped-file-data="mappedFileData = $event"
      @confirmed="step1 = true"
      @toggle-collapse="(collapse) => !collapse ? backToStep(1) : null"
      @refresh="$emit('refresh')"
    />

    <import-excel-to-json-step2-map-data
      :target-headers.sync="targetHeadersLocal"
      :entity="entity"
      :collapse-action="step1 && !completed"
      :confirmed="step2"
      :confirmed-previous-step="step1"
      :file-headers.sync="fileHeaders"
      :mapped-file-data="mappedFileData"
      :create-attributes="createAttributes"
      :count-imported-header="countImportedHeader"
      @confirmed="step2 = true"
      @toggle-collapse="(collapse) => !collapse ? backToStep(2) : null"
      @attribute-created="$emit('attribute-created', $event)"
    />

    <import-excel-to-json-step3-confirm-import
      :entity="entity"
      :collapse-action="step2 && !completed"
      :confirmed="step3"
      :confirmed-previous-step="step2"
      :file-headers="fileHeaders"
      :mapped-file-data="mappedFileData"
      :default-attributes="defaultAttributes"
      :on-save-import="onSaveImport"
      :count-imported-header="countImportedHeader"
      :import-confirm-is-valid="importConfirmIsValid"
      :target-headers="targetHeadersLocal"
      @to-import-count="toImportCount = $event"
      @mapped-import-headers="mappedImportHeaders = $event"
      @mapped-import-data="mappedImportData = $event"
      @confirmed="confirmStep3"
    >
      <template #default-attributes>
        <slot name="default-attributes"></slot>
      </template>

      <template #additional-options>
        <slot name="additional-options"></slot>
      </template>
    </import-excel-to-json-step3-confirm-import>

    <import-excel-to-json-step4-import-report
      :entity="entity"
      :collapse-action="step3 && !completed"
      :confirmed="completed"
      :confirmed-previous-step="step3"
      :file-headers="fileHeaders"
      :mapped-file-data="mappedFileData"
      :import-result="importResult"
      :to-import-count="toImportCount"
      :mapped-import-headers="mappedImportHeaders"
      :mapped-import-data="mappedImportData"
      @close="$emit('close')"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
// Custom components
import ImportExcelToJsonStep1UploadFile from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonStep1UploadFile.vue';
import ImportExcelToJsonStep2MapData from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonStep2MapData.vue';
import ImportExcelToJsonStep3ConfirmImport from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonStep3ConfirmImport.vue';
import ImportExcelToJsonStep4ImportReport from '@/views/modules/_components/import-exce-to-json/ImportExcelToJsonStep4ImportReport.vue';

export default {
  name: 'ImportExcelToJson',
  i18n: {
    messages: {
      en: {
        FileImportTitle: 'File import',
      },
    },
  },
  components: {
    ImportExcelToJsonStep1UploadFile,
    ImportExcelToJsonStep2MapData,
    ImportExcelToJsonStep3ConfirmImport,
    ImportExcelToJsonStep4ImportReport,
  },
  props: {
    targetHeaders: {
      type: Array,
      required: true,
      validator(columns) {
        return columns.every((h) => h !== null
          && typeof h === 'object'
          && 'headerName' in h
          && 'field' in h
          && 'required' in h);
      },
    },
    entity: {
      type: String,
      required: true,
    },
    createAttributes: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultAttributes: {
      type: Array,
      required: false,
      validator(attributes) {
        return attributes.every((attr) => attr !== null
          && typeof attr === 'object'
          && 'headerName' in attr
          && 'field' in attr
          && 'value' in attr);
      },
      default() {
        return [];
      },
    },
    onSaveImport: {
      type: Function,
      required: true,
    },
    importConfirmIsValid: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      targetHeadersLocal: [...this.targetHeaders],
      fileHeaders: [],
      step1: false,
      step2: false,
      step3: false,
      completed: false,
      importResult: null,

      mappedFileData: [],
      toImportCount: 0,
      mappedImportHeaders: [],
      mappedImportData: [],
    };
  },
  computed: {
    countImportedHeader() {
      let count = 0;

      this.fileHeaders.forEach((h, index) => {
        if (index < this.targetHeadersLocal.length
          && this.targetHeadersLocal[index].field !== null) {
          count += 1;
        }
      });

      return count;
    },
  },
  methods: {
    async confirmStep3(importResult) {
      this.importResult = importResult;
      this.step3 = true;
      this.completed = true;
      this.$emit('imported');
    },
    backToStep(step) {
      switch (step) {
        case 1:
          this.step1 = false;
          this.step2 = false;
          this.step3 = false;

          if (this.$refs.card2 && !this.$refs.card2.isContentCollapsed()) {
            this.$refs.card2.toggleContent();
          }

          if (this.$refs.card3 && !this.$refs.card3.isContentCollapsed()) {
            this.$refs.card3.toggleContent();
          }

          break;
        case 2:
          this.step2 = false;
          this.step3 = false;

          if (this.$refs.card3 && !this.$refs.card3.isContentCollapsed()) {
            this.$refs.card3.toggleContent();
          }
          break;

        default:
      }
    },
  },
};
</script>

<style scoped>
</style>
