<template>
  <div>
    <v-select
      v-model="valueLocal"
      :disabled="disabled"
      class="w-full"
      :class="{'border-danger': errors.has(fieldName)}"
      label="name"
      :multiple="true"
      :close-on-select="false"
      :placeholder="$t('SelectPlaceholder')"
      :options="items"
      :clearable="true">
    </v-select>

    <vs-input
      :value="valueLocal.length > 0 ? 'valid' : ''"
      type="hidden"
      :name="fieldName"
      v-validate="'required'"
      data-vv-validate-on="input|blur"
      :danger="errors.has(fieldName)"
      :danger-text="errors.first(fieldName)"/>
  </div>
</template>

<script>
import vSelect from 'vue-select';

// Mixins
import filterMixin from '@/views/modules/segment/create-or-edit/_mixins/filter.mixin';
import categoriesTagsFilterMixin from '@/views/modules/segment/create-or-edit/_mixins/categories-tags-filter.mixin';

/**
 * Categories filter for create or edit segment
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditCategoriesFilter
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'SegmentListCreateOrEditCategoriesFilter',
  i18n: {
    messages: {
      en: {
        SelectPlaceholder: 'select one or more categories',
      },
    },
  },
  components: {
    vSelect,
  },
  mixins: [filterMixin, categoriesTagsFilterMixin],
  props: {
    value: {
      type: [String, Array],
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
