<template>
  <div>
    {{ params.value | date }}
  </div>
</template>

<script>

/**
 * Cell renderer to show date values
 *
 * @module views/modules/components/cell-renderer/CellRendererDate
 * @author Dilan Useche <dilan8810@gmail.com>
 */
export default {
  name: 'CellRendererDate',
};
</script>
