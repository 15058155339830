<template>
  <div class="filter-input-value">
    <date-picker
      v-if="filterType === $enums.AppFilterType.DATE
        && condition === $enums.AppFilterOperation.IN_RANGE"
      v-model="valueLocal"
      :disabled="disabled"
      range
      format="DD-MM-YYYY"
      :placeholder="$t('SelectDateRange')"
      :clearable="false"
      @blur="$emit('blur')"/>
    <vs-input
      v-else
      v-model="valueLocal"
      class="w-full"
      :disabled="disabled"
      :type="filterType"
      :placeholder="getInputPlaceholder"
      :name="getInputName"
      v-validate="'required'"
      data-vv-validate-on="input|blur"
      :danger="errors.has(getInputName)"
      :danger-text="errors.first(getInputName)"
      val-icon-danger="clear"
      @blur="$emit('blur')"/>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import enums from '@/enums';

// Mixins
import filterMixin from '@/views/modules/segment/create-or-edit/_mixins/filter.mixin';

/**
 * input values for filters
 *
 * @module views/modules/segment/create-or-edit/SegmentListCreateOrEditFilterInputValue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} filterType - filter type
 * @vue-prop {string} condition - condition of filter
 * @vue-prop {boolean} firstCondition - indicate if value belongs to first condition
 * @vue-prop {1 | 2} inRangePosition - in range position of select
 * @vue-computed {string} getInputName - input name
 * @vue-computed {string} getInputPlaceholder - input placeholder
 */
export default {
  name: 'SegmentListCreateOrEditFilterInputValue',
  i18n: {
    messages: {
      en: {
        FromInRangeLabel: 'from',
        ToInRangeLabel: 'to',
        SelectDateRange: 'Select date range',
      },
    },
  },
  components: {
    DatePicker,
  },
  mixins: [filterMixin],
  props: {
    filterType: {
      type: String,
      required: true,
      validator(type) {
        return Object.values(enums.AppFilterType).includes(type);
      },
    },
    condition: {
      type: String,
      required: true,
      validator(type) {
        return Object.values(enums.AppFilterOperation).includes(type);
      },
    },
    firstCondition: {
      type: Boolean,
      required: false,
      default: true,
    },
    inRangePosition: {
      type: Number,
      required: true,
      validator(pos) {
        return pos === 1 || pos === 2;
      },
    },
  },
  computed: {
    getInputName() {
      let inputName = this.fieldName;

      if (this.condition && this.condition === this.$enums.AppFilterOperation.IN_RANGE) {
        inputName += ' ';
        inputName += (this.inRangePosition === 1
          ? this.$t('FromInRangeLabel')
          : this.$t('ToInRangeLabel'));
      }

      if (!this.firstCondition) {
        inputName += ' 2';
      }

      return inputName;
    },
    getInputPlaceholder() {
      if (this.condition && this.condition === this.$enums.AppFilterOperation.IN_RANGE) {
        return this.inRangePosition === 1
          ? this.$t('FromInRangeLabel')
          : this.$t('ToInRangeLabel');
      }

      return '';
    },
  },
};
</script>

<style scoped>

</style>
